import api from "config/axios.config";
import { IVip } from "types/admin.type";

interface Props {
  email: string;
}

export async function getVip({ email }: Props): Promise<IVip> {
  return api.get(`vips/${email}`);
}
