import { auth } from "config/firebase.config";
import { User } from "firebase/auth";
import Cookies from "js-cookie";
import _ from "lodash";
import { ReactNode, createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { findOrCreateAdmin } from "services/admin.service";
import { SignOutUser, userStateListener } from "services/firebase.service";
import { Admin } from "types/admin.type";
import RolesType from "types/roles.type";

interface Props {
  children?: ReactNode;
}

interface CustomUser extends User {
  accessToken?: string;
}

export const AuthContext = createContext({
  currentUserFirebase: {} as CustomUser | null,
  setCurrentUserFirebase: (_user: CustomUser) => {},
  currentAdmin: {} as Admin | null,
  setCurrentAdmin: (_admin: Admin) => {},
  clientId: "" as string | null,
  setClientId: (_clientId: string) => {},
  signOut: () => {},
  isLoading: true,
});

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [currentUserFirebase, setCurrentUserFirebase] = useState<CustomUser | null>(null);
  const [currentAdmin, setCurrentAdmin] = useState<Admin | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true); // Ajoutez une state isLoading

  const fetchAdmin = async (uid: string) => {
    try {
      if (currentAdmin) return;

      const response = await findOrCreateAdmin(uid);

      setCurrentAdmin(response);
      const clientId = _.get(response, "roleByClient[0].clientId") as string;
      const roleAdmin = _.get(response, "role");
      const isSuperAdmin = roleAdmin.includes(RolesType.SUPER_ADMIN);
      const savedClientId = Cookies.get("selectedClientId");

      const currentClientId = isSuperAdmin ? savedClientId || clientId : clientId;

      setClientId(currentClientId);
    } catch (error) {
      console.error("Error when retrieving the role :", error);
      await signOut();
    }
  };

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {
      if (user) {
        setCurrentUserFirebase(user);
        // fetchAdmin(user.uid);
        if (!user.emailVerified) navigate("/authentication/verify-email");
        else fetchAdmin(user.uid);
      }
      setIsLoading(false);
    });
    return unsubscribe;
  }, [auth, navigate, setCurrentUserFirebase]);

  const signOut = async () => {
    await SignOutUser();
    await setCurrentUserFirebase(null);
    await setCurrentAdmin(null);
    await setClientId(null);
    navigate("/authentication/sign-in");
  };

  if (isLoading) {
    return <div>Chargement en cours...</div>; // TODO Create an Hopelapp Loading page
  }

  const value = {
    currentUserFirebase,
    setCurrentUserFirebase,
    currentAdmin,
    setCurrentAdmin,
    clientId,
    setClientId,
    signOut,
    isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
