const form = {
  formId: "design-navigation-form",
  formField: {
    description: {
      name: "description",
      type: "text",
      errorMsgKey: "validationSchema.required.description",
    },
    address: {
      name: "address",
      type: "text",
    },
    linkAddress: {
      name: "linkAddress",
      type: "text",
    },
    webSite: {
      name: "webSite",
      type: "text",
    },
    twitter: {
      name: "twitter",
      type: "text",
    },
    facebook: {
      name: "facebook",
      type: "text",
    },
    instagram: {
      name: "instagram",
      type: "text",
    },
    linkedin: {
      name: "linkedin",
      type: "text",
    },
    bgColorCard: {
      name: "bgColorCard",
      type: "color",
      errorMsgKey: "validationSchema.required.bgColor",
    },
    openingHours: {
      name: "openHours",
      type: "array",
      errorMsgKey: "validationSchema.required.openingHours",
    },
  },
};

export default form;
