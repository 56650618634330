import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createClient } from "services/client.service";
import { IVip } from "types/admin.type";
import { IClient, ICreateClient } from "types/client.type";
import { getVip } from "./create-app.service";

export enum KeyQueryClient {
  getClients = "getClients",
  getVips = "getVips",
}

export const useCreateClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ICreateClient) => createClient(data),
    onSuccess: async (result: any) => {
      queryClient.setQueryData([KeyQueryClient.getClients], (clients: IClient[] | undefined) =>
        clients ? [...clients, result] : [result]
      );
    },
  });
};

export const useGetVipByEmail = (email: string): UseQueryResult<IVip> => {
  return useQuery([KeyQueryClient.getVips, email], async () => {
    if (!email) return null;
    return getVip({ email });
  });
};
