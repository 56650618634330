export enum ETypeDesign {
  HOME = "home",
  NAVIGATION = "navigation",
  CARD = "card",
  COMPONENTS = "components",
  CSSCUSTOME = "cssCustome",
}

export interface IDesignComponent {
  clientId: string;
  data: Design;
  params: IDesignConst;
}

export interface IDesignConst {
  id: ETypeDesign;
  name: string;
  component: (value: IDesignComponent) => JSX.Element;
  constants: any;
}

export interface IDesignLang {
  fr: IDesignConst[];
  en: IDesignConst[];
}

export type Design = {
  home?: DesignHome;
  navigation?: DesignNavigation;
  card?: DesignCard;
  components?: DesignComponents;
  information?: DesignInformation;
  cssCustome?: string;
};

export type DesignHome = {
  type: EDesignTypeHome;
  sections: ISection[];
};

export type DesignNavigation = {
  type: EDesignTypeNavigation;
  bgColor: string;
  textColor: string;
  activeBgColor: string;
};

export type DesignCard = {
  type: EDesignTypeCard;
  bgColor: string;
  textColor: string;
  linkColor: string;
};

export type DesignComponents = {
  button: TypeComponents;
  buttonCancel: TypeComponents;
  input: TypeComponents;
  radio: TypeComponents;
  checkbox: TypeComponents;
  text: TypeComponents;
};

export type DesignInformation = {
  bgColorCard: string;
};

export type ISections = {
  section1: ISection;
  section2: ISection;
  section3?: ISection;
  section4?: ISection;
};

export interface ISection {
  type: ETypeSections;
  order: number;
  bgColor: string;
  buttonBgColor?: string;
  textColor?: string;
  borderColor?: string;
  buttonActiveBgColor?: string;
  textActiveColor?: string;
  borderActiveColor?: string;
}

export enum EDesignTypeHome {
  MENUDISPLAY = "menuDisplay",
  PRODUCTDISPLAY = "productDisplay",
}

export enum EDesignTypeNavigation {
  NAVIGATIONSIDEBAR = "navigationSidBar",
  FOOTERNAVIGATION = "footerNavigation",
}

export enum EDesignTypeCard {
  BORDER = "border",
  NOBORDER = "noBorder",
}

export enum ETypeSections {
  CAROUSEL = "carousel",
  CATEGORY = "category",
  SEARCHBAR = "searchBar",
  PRODUCTS = "products",
}

export type TypeComponents = {
  type?: number;
  bgColor?: string;
  textColor?: string;
};
