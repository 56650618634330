import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField/FormField";
import { useTranslation } from "react-i18next";

const AppInfo = ({ formData }: any): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { name, email, activity, description } = formField;
  const { name: nameV, email: emailV, activity: activityV, description: descriptionV } = values;
  const activityItems = t("createApp.activityItems", { returnObjects: true }) as any;
  const activityItemsArray = activityItems.map((item: string) => item);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">{t("createApp.information")}</MDTypography>
        <MDTypography variant="button" color="text">
          {t("createApp.descriptionInfo")}
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              label={t("createApp.nameApp")}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={t("createApp.email")}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              options={activityItemsArray}
              onChange={(e, value) => {
                setFieldValue(activity.name, value !== null ? value : "");
              }}
              onInputChange={(e, value) => {
                setFieldValue(activity.name, value !== null ? value : "");
              }}
              renderInput={(params) => (
                <FormField
                  {...params}
                  type={activity.type}
                  label={t("createApp.activity")}
                  name={activity.name}
                  value={activityV}
                  placeholder={activity.placeholder}
                  error={errors.activity && touched.activity}
                  success={activityV.length > 0 && !errors.activity}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox mt={-1.625}>
              <FormField
                type={description.type}
                label={t("createApp.descriptionApp")}
                name={description.name}
                value={descriptionV}
                placeholder={description.placeholder}
                multiline
                rows={3}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default AppInfo;
