import * as Yup from "yup";
import checkout from "pages/myApp/schemas/information/form";
import i18next from "i18n";

const {
  formField: { description, bgColorCard },
} = checkout;

const validations = Yup.object().shape({
  [bgColorCard.name]: Yup.string().required(i18next.t(bgColorCard.errorMsgKey)),
  [description.name]: Yup.string().required(i18next.t(description.errorMsgKey)),
});

export default validations;
