import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { AuthContext } from "context/auth-context/auth.context";
import RequireAuth from "context/auth-context/require-auth.provider";
import {
  setMiniSidenav,
  useMaterialUIController,
} from "context/materialUI-context/materialUI.context";
import Sidenav from "examples/Sidenav";
import { useGetClientById } from "pages/client/client.hook";
import { JSXElementConstructor, Key, ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes from "routes";
import { SELECT_CUSTOMERS } from "types/client.type";
import RolesType from "types/roles.type";
const App = () => {
  const { t } = useTranslation();

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentUserFirebase, currentAdmin } = useContext(AuthContext);
  const { data, isLoading } = useGetClientById(SELECT_CUSTOMERS);
  const isSuperAdmin = currentAdmin?.role?.includes(RolesType.SUPER_ADMIN);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (
      currentUserFirebase?.emailVerified &&
      window.location.pathname.startsWith("/authentication")
    ) {
      navigate("/");
    }

    if (
      currentAdmin &&
      currentAdmin.roleByClient?.length &&
      window.location.pathname.startsWith("/create/app")
    ) {
      navigate("/");
    }
  }, [currentAdmin, currentUserFirebase, navigate]);

  useEffect(() => {
    if (currentAdmin) {
      if (!isSuperAdmin && !currentAdmin.roleByClient?.length) navigate("/create/app");
    }
  }, [currentAdmin]);

  const getRoutes = (allRoutes: any[]): any =>
    allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        privateRoute: boolean;
      }) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          if (route.privateRoute) {
            return (
              <Route
                path={route.route}
                element={<RequireAuth>{route.component}</RequireAuth>}
                // element={route.component}
                key={route.key}
              />
            );
          } else return <Route path={route.route} element={route.component} key={route.key} />;
        }

        return null;
      }
    );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" &&
        currentUserFirebase &&
        currentAdmin &&
        currentAdmin.roleByClient?.length && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={data?.logo || null}
              codeClient={data?.code || null}
              brandName={data?.name || t("app.loading")}
              routes={routes(currentAdmin, currentUserFirebase)}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              isSuperAdmin
            />
            {/* <Configurator /> */}
            {/* {configsButton} */}
          </>
        )}
      <Routes>
        {getRoutes(routes(currentAdmin, currentUserFirebase))}
        {currentUserFirebase && currentAdmin && currentAdmin.roleByClient?.length ? (
          <Route path="*" element={<Navigate to="/myApp" />} />
        ) : (
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        )}
      </Routes>
      {/* {layout === "vr" && <Configurator />} */}
    </ThemeProvider>
  );
};

export default App;
