import { debounce } from "@mui/material";
import MDInput from "components/MDInput";
import React from "react";

interface Props {
  fieldName: string;
  label: string;
  value: string;
  setFieldValue: (field: string, value: any) => void;
}

const SelectColor = ({ setFieldValue, fieldName, value, label }: Props) => {
  const delayedSetFieldValue = debounce((field: string, valueColor) => {
    setFieldValue(field, valueColor);
  }, 100);

  return (
    <MDInput
      fullWidth
      type="color"
      label={label}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        delayedSetFieldValue(fieldName, event.target.value)
      }
    />
  );
};

export default SelectColor;
