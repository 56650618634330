import { Icon, MenuItem, Select } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Modal, { EType } from "components/Modal/modal";
import { useGetClientById } from "pages/client/client.hook";
import ModalDelete from "pages/element/components/ModalDelete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SELECT_FEATURES } from "types/client.type";
import { EFeatures } from "types/features.type";
import {
  EMenuElement,
  ETypeActionButtonProduct,
  ETypeElement,
  IAttribute,
  ISubCategory,
} from "../element.type";

import verifyLicence from "utils/verifyLicence";

interface Props {
  subCategories: ISubCategory[];
  allAttributes: IAttribute[];
  formData: any;
  defaultImage: string;
  cropImage: (image: string) => void;
  handleDeleteProduct: () => void;
  menu: EMenuElement;
  children: React.ReactNode;
}

export const ProductUpdateInfoAction = ({
  formData,
  handleDeleteProduct,
  children,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState<string | null>(null);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);

  const { data } = useGetClientById(SELECT_FEATURES);

  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    inCarousel,
    productActionType,
    link,
    isMultipleReservation,
    isAutomaticConfirmation,
    durationDays,
    durationHours,
    durationMinutes,
    typeElement,
  } = formField;
  const {
    name: nameV,
    inCarousel: inCarouselV,
    productActionType: productActionTypeV,
    link: linkV = values.link ? values.link : "",
    isMultipleReservation: isMultipleReservationV,
    isAutomaticConfirmation: isAutomaticConfirmationV,
    durationDays: durationDaysV,
    durationHours: durationHoursV,
    durationMinutes: durationMinutesV,
    typeElement: typeElementV,
  } = values;

  const isNone = [ETypeActionButtonProduct.NONE, "", null, undefined].includes(productActionTypeV);

  const handleDelete = async () => {
    try {
      setInfoDelete(t("alert.delete.content", { item: nameV }));
      await handleDeleteProduct();
      setOpenModalDelete(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: nameV }));
    }
  };

  const handleClose = () => setOpenModalDelete(false);

  const renderModalDelete = (
    <Modal
      title={t("element.modalDelete.title")}
      openModal={openModalDelete}
      body={<ModalDelete name={nameV} />}
      handleValid={handleDelete}
      handleClose={handleClose}
      type={EType.delete}
    />
  );

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={infoDelete}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  return (
    <>
      <Card style={{ overflowY: "auto" }}>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <MDBox display="flex" justifyContent="flex-start">
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color={inCarouselV ? "success" : "default"}
                    onClick={() => setFieldValue(inCarousel.name, !inCarouselV)}
                    iconOnly
                  >
                    <Icon>push_pin</Icon>
                  </MDButton>
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color="info"
                    onClick={() => navigate("/element/new")}
                    iconOnly
                  >
                    <Icon>add</Icon>
                  </MDButton>
                  <MDButton
                    style={{ marginRight: 10 }}
                    variant="gradient"
                    color="error"
                    onClick={() => setOpenModalDelete(true)}
                    iconOnly
                  >
                    <Icon>delete</Icon>
                  </MDButton>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                {children}
              </Grid>
            </Grid>
          </MDBox>
          {/* Type Section */}
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    {t("form.productActionType")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={typeElementV}
                    defaultValue={typeElementV || ETypeActionButtonProduct.NONE}
                    onChange={(value) => {
                      setFieldValue(productActionType.name, value.target.value);
                      setFieldValue(typeElement.name, value.target.value);
                    }}
                    label={t("form.productActionType")}
                  >
                    <MenuItem value={ETypeActionButtonProduct.NONE}>
                      <em>{t("element.none")}</em>
                    </MenuItem>
                    <MenuItem value={ETypeActionButtonProduct.DETAIL}>
                      {t("element.moreDetail")}
                    </MenuItem>
                    {verifyLicence(data, EFeatures.BOOKINGAPPOINTMENT) ? (
                      <MenuItem value={ETypeActionButtonProduct.APPOINTMENT}>
                        {t("element.rdv")}
                      </MenuItem>
                    ) : null}
                    {verifyLicence(data, EFeatures.RENT) ? (
                      <MenuItem value={ETypeActionButtonProduct.RENT}>{t("element.rent")}</MenuItem>
                    ) : null}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </MDBox>

          {/* Redirection detail Section */}
          {typeElementV == ETypeElement.DETAIL && (
            <MDBox mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <MDBox mb={3}>
                    <FormField
                      type={link.type}
                      label={t("form.link")}
                      name={link.name}
                      value={linkV}
                      placeholder={link.placeholder}
                      success={linkV?.length > 0 && !errors.link}
                      error={!isNone || (errors.title && touched.title)}
                      // disabled={isNone}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          )}

          {/* Appointment Section */}
          {typeElementV == ETypeElement.APPOINTMENT && (
            <>
              <MDBox mt={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        {t("form.isMultipleReservation")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={isMultipleReservationV}
                        onChange={(value) => {
                          setFieldValue(isMultipleReservation.name, value.target.value);
                        }}
                        label={t("form.isMultipleReservation")}
                      >
                        <MenuItem value={"true"}>{t("common.true")}</MenuItem>
                        <MenuItem value={"false"}>{t("common.false")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        {t("form.isAutomaticConfirmation")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={isAutomaticConfirmationV}
                        onChange={(value) => {
                          setFieldValue(isAutomaticConfirmation.name, value.target.value);
                        }}
                        label={t("form.isAutomaticConfirmation")}
                      >
                        <MenuItem value={"true"}>{t("common.true")}</MenuItem>
                        <MenuItem value={"false"}>{t("common.false")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        {t("form.durationHours")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={durationHoursV}
                        onChange={(value) => {
                          setFieldValue(durationHours.name, value.target.value);
                        }}
                        label={t("form.durationHours")}
                      >
                        <MenuItem value={0}>{"0h"}</MenuItem>
                        <MenuItem value={1}>{"1h"}</MenuItem>
                        <MenuItem value={2}>{"2h"}</MenuItem>
                        <MenuItem value={3}>{"3h"}</MenuItem>
                        <MenuItem value={4}>{"4h"}</MenuItem>
                        <MenuItem value={5}>{"5h"}</MenuItem>
                        <MenuItem value={6}>{"6h"}</MenuItem>
                        <MenuItem value={7}>{"7h"}</MenuItem>
                        <MenuItem value={8}>{"8h"}</MenuItem>
                        <MenuItem value={9}>{"9h"}</MenuItem>
                        <MenuItem value={10}>{"10h"}</MenuItem>
                        <MenuItem value={11}>{"11h"}</MenuItem>
                        <MenuItem value={12}>{"12h"}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        {t("form.durationMinutes")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={durationMinutesV}
                        onChange={(value) => {
                          setFieldValue(durationMinutes.name, value.target.value);
                        }}
                        label={t("form.durationMinutes")}
                      >
                        <MenuItem value={0}>{"0m"}</MenuItem>
                        <MenuItem value={5}>{"5m"}</MenuItem>
                        <MenuItem value={10}>{"10m"}</MenuItem>
                        <MenuItem value={15}>{"15m"}</MenuItem>
                        <MenuItem value={20}>{"20m"}</MenuItem>
                        <MenuItem value={25}>{"25m"}</MenuItem>
                        <MenuItem value={30}>{"30m"}</MenuItem>
                        <MenuItem value={35}>{"35m"}</MenuItem>
                        <MenuItem value={40}>{"40m"}</MenuItem>
                        <MenuItem value={45}>{"45m"}</MenuItem>
                        <MenuItem value={50}>{"50m"}</MenuItem>
                        <MenuItem value={55}>{"55m"}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          )}
          {/* Rent Section */}
          {typeElementV === ETypeElement.RENT && <></>}
        </MDBox>
      </Card>
      {renderModalDelete}
      {renderAlertDelete}
      {renderAlertError}
    </>
  );
};

// export default React.memo(ProductUpdateInfo);
