const form = {
  formId: "product-form",
  formField: {
    name: {
      name: "name",
      type: "text",
      errorMsgKey: "validationSchema.required.name",
    },
    title: {
      name: "title",
      type: "title",
      errorMsgKey: "validationSchema.required.title",
    },
    price: {
      name: "price",
      type: "number",
      errorMsgKey: "validationSchema.required.price",
    },
    category: {
      name: "subCategoryId",
      type: "autocomplete",
      errorMsgKey: "validationSchema.required.category",
    },
    description: {
      name: "description",
      type: "text",
      errorMsgKey: "validationSchema.required.description",
    },
    image: {
      name: "image",
      type: "text",
      errorMsgKey: "validationSchema.required.image",
    },
    file: {
      name: "file",
      type: "file",
      errorMsgKey: "validationSchema.required.file",
    },
    attributes: {
      name: "attributes",
      type: "array",
    },
    inCarousel: {
      name: "inCarousel",
      type: "boolean",
    },
    link: {
      name: "link",
      type: "string",
    },
    productActionType: {
      name: "productActionType",
      type: "string",
    },
    isMultipleReservation: {
      name: "isMultipleReservation",
      type: "string",
    },
    isAutomaticConfirmation: {
      name: "isAutomaticConfirmation",
      type: "string",
    },
    durationDays: {
      name: "durationDays",
      type: "number",
    },
    durationHours: {
      name: "durationHours",
      type: "number",
    },
    durationMinutes: {
      name: "durationMinutes",
      type: "number",
    },
    typeElement: {
      name: "typeElement",
      type: "string",
    },
  },
};

export default form;
