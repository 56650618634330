import { FormControl, Grid, Icon, MenuItem, Select } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal, { EType } from "components/Modal/modal";
import DataTable from "examples/Tables/DataTable";
import ModalDelete from "pages/order/components/ModalDelete";
import Calendar from "layouts/Calendar";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetAllReservation } from "../../reservation/reservations.hook";
import { ETypeAction, IBasket, IEvent, IOrder, IStatusOrder } from "../order.type";
// eslint-disable-next-line import/extensions
import ModalInfoOrder from "../components/ModalInfoOrder";
import { useGetClientById } from "pages/client/client.hook";
import { ECurrency, SELECT_INFORMATION } from "types/client.type";
import moment from "moment";
import { mockEvents } from "pages/element/data/data";

const CalendarPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalCreateOrderRef = useRef(null);
  const { data, isLoading } = useGetAllReservation();
  const [fakeData, setFakeData] = useState([]);
  const { data: dataClient } = useGetClientById(SELECT_INFORMATION);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [typeAction, setTypeAction] = useState<ETypeAction>(ETypeAction.CREATE);
  const [openAlertCreateOrder, setOpenAlertCreateOrder] = useState(false);
  const [selectOrder, setSelectOrder] = useState<IOrder | null>(null);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const [errorAlertTitleOrder, setErrorAlertTitleOrder] = useState<string | null>(null);
  const [errorAlertContentOrder, setErrorAlertContentOrder] = useState<string | null>(null);

  /*const handleActionUpdate = async (order: IOrder, status: IStatusOrder) => {
    try {
      await updateOrder.mutateAsync({
        orderId: order._id,
        data: { status: status },
      });
    } catch (error) {
      console.error("error:", error);
    }
  };*/

  const handleOpenOrder = (order: IOrder) => {
    setSelectOrder(order);
    setTypeAction(ETypeAction.UPDATE);
    setOpenModalCreate(true);
  };

  const handleActionDelete = (order: IOrder) => {
    setSelectOrder(order);
    setOpenModal(true);
  };

  /*const handleDelete = async () => {
    try {
      await deleteOrder.mutateAsync(selectOrder?._id);
      setOpenModal(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: selectOrder?.name }));
    }
  };*/

  const handleClose = () => setOpenModal(false);

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={t("alert.delete.content", { item: selectOrder?.name })}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  /*const renderModalDelete = (
    <Modal
      title={t("order.modalDelete.title")}
      openModal={openModal}
      body={<ModalDelete name={selectOrder?.name} />}
      handleValid={handleDelete}
      handleClose={handleClose}
      type={EType.delete}
    />
  );*/

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!data);
    }, 2000);
  };

  const renderCreateOrUpdateOrder = (
    <Modal
      title={t(`order.modal.${typeAction}.title`)}
      openModal={openModalCreate}
      body={<ModalInfoOrder currentOrder={selectOrder} ref={modalCreateOrderRef} />}
      handleClose={() => setOpenModalCreate(false)}
      valid={false}
    />
  );

  /*useEffect(() => {
    if (!data) {
      setFakeData(mockEvents);
    }
  }, []);*/
  return (
    <>
      <MDBox pt={1} pb={3}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {fakeData ? (
              <Card>
                <Calendar
                  header={{}}
                  events={data}
                  eventClick={(info: any) => {
                    info.jsEvent.preventDefault(); // don't let the browser navigate
                  }}
                />
              </Card>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("order.errorGetOrder")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </MDBox>

      {/*renderModalDelete*/}
      {renderAlertDelete}
      {renderAlertError}
      {renderCreateOrUpdateOrder}
    </>
  );
};

export default React.memo(CalendarPage);
