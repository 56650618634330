import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDDropzone from "components/MDDropzone";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import _ from "lodash";
import form from "pages/element/schemas/attribute/form";
import validations from "pages/element/schemas/attribute/validations";
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useCreateAttribute, useUpdateAttribute } from "../element.hook";
import { IAttribute } from "../element.type";

interface Props {
  onFormSubmit: () => void;
  onError: (error: any) => void;
  currentAttribute?: IAttribute;
}

const ModalCreateOrUpdateAttribute = forwardRef(
  ({ onFormSubmit, onError, currentAttribute }: Props, ref) => {
    const { t } = useTranslation();
    const { formId, formField } = form;
    const { title, subTitle, file } = formField;
    const formikRef = useRef(null);
    const createSubAttribute = useCreateAttribute();
    const updateAttribute = useUpdateAttribute();

    const initialValues = {
      [title.name]: currentAttribute?.name || "",
      [subTitle.name]: currentAttribute?.value || "",
      [file.name]: "",
    };

    const handleSubmit = async (values: any) => {
      try {
        if (currentAttribute) {
          await updateAttribute.mutateAsync({
            attributeId: currentAttribute._id,
            data: values,
          });
        } else await createSubAttribute.mutateAsync({ data: values });

        if (onFormSubmit) onFormSubmit();
      } catch (error) {
        if (onError) onError(error);
      }
    };

    useImperativeHandle(ref, () => ({
      async submitForm() {
        if (formikRef.current) await formikRef.current.submitForm();
      },
    }));

    return (
      <>
        <MDBox mt={3} mb={1} ml={0.5}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, touched, setFieldValue }) => {
              const { title, subTitle, file } = formField;
              const { title: titleV, subTitle: subTitleV } = values;

              return (
                <Form id={formId} autoComplete="off">
                  <MDBox my={3}>
                    <Grid container spacing={6}>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <MDBox mt={1}>
                            <MDTypography
                              component="label"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              {t("form.image")}
                            </MDTypography>
                            {useMemo(
                              () => (
                                <MDDropzone
                                  options={{
                                    addRemoveLinks: false,
                                    maxFiles: 1,
                                    acceptedFiles:
                                      "image/png, image/jpg, image/jpeg, image/svg+xml",
                                  }}
                                  onChange={(fileImage) => setFieldValue(file.name, fileImage)}
                                  defaultImageUrl={_.get(currentAttribute, "mediaId.url") || null}
                                  error={errors.file}
                                />
                              ),
                              []
                            )}
                          </MDBox>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <FormField
                            type={title.type}
                            label={t("form.title")}
                            name={title.name}
                            value={titleV}
                            error={errors.name && touched.name}
                            success={titleV?.length > 0 && !errors.name}
                          />
                        </Grid>
                        <Grid item xs={12} mt={4}>
                          <FormField
                            type={subTitle.type}
                            label={t("form.subTitle")}
                            name={subTitle.name}
                            value={subTitleV}
                            error={errors.name && touched.name}
                            success={subTitleV?.length > 0 && !errors.name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </>
    );
  }
);

export default ModalCreateOrUpdateAttribute;
