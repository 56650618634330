import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Navigation from "components/NavigationPage/Navigation";
import ListeProduct from "pages/element/pages/ListeProduct";
import Cartography from "pages/element/pages/Cartography";
import { useTranslation } from "react-i18next";
import ListeCategory from "pages/element/pages/ListeCategory";
import ListeAttribute from "pages/element/pages/ListeAttribute";

const Index = () => {
  const { t } = useTranslation();

  const tabs = [
    { label: t("element.tabs.element"), content: <ListeProduct />, mode: "inside" },
    {
      label: t("element.tabs.category"),
      content: <ListeCategory />,
      mode: "inside",
    },
    {
      label: t("element.tabs.attribute"),
      content: <ListeAttribute />,
      mode: "inside",
    },
    { label: "Cartographie", content: <Cartography />, mode: "modal" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Navigation stickyNavbar={true} tabs={tabs}>
        <Footer />
      </Navigation>
    </DashboardLayout>
  );
};

export default Index;
