import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SelectColor from "components/Select/SelectColor";
import { ISection } from "pages/design/design.type";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  section: ISection;
  setFieldValue: any;
  index: number;
}

const SectionProduct = memo(({ section, setFieldValue, index }: Props) => {
  const { t } = useTranslation();
  const { bgColor } = section;

  return (
    <MDBox mt={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <MDTypography variant="body2">{t("design.section.element.title")}</MDTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <SelectColor
                fieldName={`sections[${index}].bgColor`}
                label={t("form.bgColor")}
                value={bgColor || "#ffffff"}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </MDBox>
  );
});

export default SectionProduct;
