// Logout.jsx
import { AuthContext } from "context/auth-context/auth.context";
import React, { useEffect, useContext } from "react";

const Logout = () => {
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    // Ajoutez ici la logique de déconnexion en utilisant le contexte AuthContext
    signOut();

    // Vous pouvez également rediriger l'utilisateur vers la page de connexion ou une autre page appropriée.
    // Exemple avec React Router :
    // navigate('/authentication/sign-in/basic');
  }, [signOut]);

  // Vous pouvez personnaliser le contenu du composant de déconnexion ici si nécessaire
  return (
    <div>
      {/* Vous pouvez également afficher un message ou un indicateur de chargement ici */}
      Logging out...
    </div>
  );
};

export default Logout;
