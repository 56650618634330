import MockupPhone from "components/MockupPhone/MockPhone";
import MockupSectionCarousel from "components/MockupPhone/MockupSectionCarousel";
import MockupSectionCategory from "components/MockupPhone/MockupSectionCategory";
import MockupSectionProduct from "components/MockupPhone/MockupSectionProduct";
import MockupSectionSearchBar from "components/MockupPhone/MockupSectionSearchBar";
import { memo } from "react";
import { ETypeSections, ISection } from "../design.type";
interface Props {
  values: {
    sections: ISection[];
  };
}

const MockupPhoneHomePage = memo(({ values }: Props): JSX.Element => {
  const { sections: sectionV } = values;

  const displayHomePage = (section: ISection) => {
    let componentToRender = null;
    switch (section.type) {
      case ETypeSections.CAROUSEL:
        componentToRender = <MockupSectionCarousel section={section} />;
        break;
      case ETypeSections.CATEGORY:
        componentToRender = <MockupSectionCategory section={section} />;
        break;
      case ETypeSections.SEARCHBAR:
        componentToRender = <MockupSectionSearchBar section={section} />;
        break;
      case ETypeSections.PRODUCTS:
        componentToRender = <MockupSectionProduct section={section} />;
        break;
      default:
        break;
    }

    return componentToRender;
  };

  return (
    <MockupPhone>
      {sectionV.map((section, index) => (
        <div key={index}>{displayHomePage(section)}</div>
      ))}
    </MockupPhone>
  );
});

export default MockupPhoneHomePage;
