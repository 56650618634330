import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import SelectColor from "components/Select/SelectColor";
import { useTranslation } from "react-i18next";

interface Props {
  formData: any;
}

const NavigationUpdate = ({ formData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, setFieldValue } = formData;
  const { bgColor, textColor, activeBgColor } = formField;
  const { bgColor: bgColorV, textColor: textColorV, activeBgColor: activeBgColorV } = values;

  return (
    <>
      <Card style={{ maxHeight: "750px", overflowY: "auto" }}>
        <MDBox p={3}>
          {/* <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("design.tabs.navigation")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox> */}
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={bgColor.name}
                  label={t("form.bgColor")}
                  value={bgColorV}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={textColor.name}
                  label={t("form.textColor")}
                  value={textColorV}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={activeBgColor.name}
                  label={t("form.activeBgColor")}
                  value={activeBgColorV}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default NavigationUpdate;
