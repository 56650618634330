import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import { Box, Grid, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MockupBarNavigation from "components/MockupPhone/MockupBarNavigation";
import { ReactNode, memo } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import MockupFooterProduct from "components/MockupPhone/MockupFooterProduct";

interface ContentProps {
  children: ReactNode;
}

interface IPhoneMockupProps {
  children: ReactNode;
  showNavigation?: boolean;
  dataFooterProduct?: any;
  styleNavigation?: {
    bgColor: string;
    textColor: string;
    activeBgColor: string;
  };
}

const MockPhone = memo(
  ({ children, styleNavigation, showNavigation = true, dataFooterProduct }: IPhoneMockupProps) => {
    const getCurrentTime = () => {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    const styleNav = {
      bgColor: styleNavigation?.bgColor,
      textColor: styleNavigation?.textColor,
      activeBgColor: styleNavigation?.activeBgColor,
    };

    const scrollbarStyle = {
      width: 275,
      height: showNavigation ? 490 : 566,
    };

    const thumbStyle = {
      backgroundColor: "rgba(128, 128, 128, 0.2)",
      borderRadius: 10,
    };

    const Content = ({ children }: ContentProps) => {
      return (
        <MDBox bgColor="light" height={"100%"} width={280}>
          <Scrollbars
            style={scrollbarStyle}
            renderThumbVertical={({ style, ...props }) => (
              <div {...props} style={{ ...style, ...thumbStyle }} />
            )}
          >
            {children}
          </Scrollbars>
          {dataFooterProduct && <MockupFooterProduct data={dataFooterProduct} />}
        </MDBox>
      );
    };

    return (
      <Paper
        sx={{
          width: 290,
          height: 600,
          margin: "auto",
          position: "relative",
          border: "8px solid #000",
          borderRadius: 12,
          overflow: "hidden",
          boxShadow: 20,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            mt: 2,
            position: "absolute",
            width: "100%",
            padding: "0 20px",
            color: "#fff",
          }}
        >
          <Grid item>
            <Typography pl={1} variant="h6" color="#000">
              {getCurrentTime()}
            </Typography>
          </Grid>
          <Grid>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#000",
                width: 100,
                height: 14,
                borderRadius: 2,
              }}
            />
          </Grid>
          <Grid item>
            <SignalCellular4BarIcon sx={{ color: "#000", marginRight: 1 }} />
            <BatteryFullIcon sx={{ color: "green" }} />
          </Grid>
        </Grid>
        {showNavigation && (
          <MockupBarNavigation
            bgColor={styleNav.bgColor}
            textColor={styleNav.textColor}
            activeBgColor={styleNav.activeBgColor}
          >
            <Content>{children}</Content>
          </MockupBarNavigation>
        )}
        <Content>{children}</Content>
      </Paper>
    );
  }
);

export default MockPhone;
