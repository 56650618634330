import * as Yup from "yup";
import checkout from "pages/design/schemas/card/form";
import i18next from "i18n";

const {
  formField: { type, bgColor, textColor, linkColor },
} = checkout;

const validations = Yup.object().shape({
  [type.name]: Yup.string().required(i18next.t(type.errorMsgKey)),
  [bgColor.name]: Yup.string().required(i18next.t(type.errorMsgKey)),
  [textColor.name]: Yup.string().required(i18next.t(type.errorMsgKey)),
  [linkColor.name]: Yup.string().required(i18next.t(type.errorMsgKey)),
});

export default validations;
