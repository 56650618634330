const form = {
  formId: "new-app-form",
  formField: {
    name: {
      name: "name",
      type: "text",
      errorMsgKey: "validationSchema.required.name",
    },
    email: {
      name: "email",
      type: "email",
      errorMsgKey: "validationSchema.required.email",
      invalidMsgKey: "validationSchema.invalid.email",
    },
    activity: {
      name: "activity",
      type: "autocomplete",
      errorMsgKey: "validationSchema.required.activity",
    },
    description: {
      name: "description",
      type: "text",
      errorMsgKey: "validationSchema.required.description",
    },
    templateId: {
      name: "templateId",
      type: "text",
      errorMsgKey: "validationSchema.required.template",
    },
  },
};

export default form;
