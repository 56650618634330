import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { AuthContext } from "context/auth-context/auth.context";
import { Form, Formik } from "formik";
import { useGetClientById } from "pages/client/client.hook";
import GeneralUpdate from "pages/technicalService/components/GeneralUpdate";
import form from "pages/technicalService/schemas/general/form";
import initialValues from "pages/technicalService/schemas/general/initialValues";
import validations from "pages/technicalService/schemas/general/validations";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SELECT_CUSTOMERS } from "types/client.type";

const CustomeGeneral = (): JSX.Element => {
  const { t } = useTranslation();
  const { formId, formField } = form;
  const [showError, setShowError] = useState(false);
  const [openAlertUpdate, setOpenAlertUpdate] = useState(false);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const { data, isLoading } = useGetClientById(SELECT_CUSTOMERS);
  const { currentAdmin } = useContext(AuthContext);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      values["orgid"] = "00D7R000005GpWi";
      values["retURL"] = "https://agi.hopelapp.com/thank-you";
      values["email"] = currentAdmin?.email;
      values["phone"] = data?.phoneAppName;
      values["00N7R00000Ft5j8"] = values?.category; // Action
      values["00N7R00000Ft5jI"] = data.code; // AppCode

      const form = document.querySelector("form");
      form.method = "POST";
      form.action =
        "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D7R000005GpWi";
      //form.setAttribute("target", customHiddenIframeName);
      for (const fieldName in values) {
        const theInput = document.createElement("input");
        theInput.name = fieldName;
        theInput.value = values[fieldName];
        theInput.setAttribute("type", "hidden");
        form.appendChild(theInput);
      }
      document.body.appendChild(form);
      form.submit();

      //TODO
      resetForm({ values });
      setOpenAlertUpdate(true);
    } catch (error: any) {
      setErrorAlertTitle(t("alert.update.titleError"));
      setErrorAlertContent(t("alert.update.contentError", { errorMsg: error.message }));
    }
  };

  const renderAlertUpdate = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.update.title")}
      content={t("alert.update.content")}
      open={openAlertUpdate}
      onClose={() => setOpenAlertUpdate(false)}
      close={() => setOpenAlertUpdate(false)}
      bgWhite
    />
  );

  const renderAlertErrorUpdate = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!data);
    }, 2000);
  };

  return (
    <MDBox mt={5} mb={50}>
      <Grid container spacing={1} style={{ display: "flex", justifyContent: "center" }}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {data ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue, dirty, isSubmitting }) => {
                  const formData = {
                    values,
                    touched,
                    formField,
                    errors,
                    setFieldValue,
                  };

                  return (
                    <Form style={{ width: "80%" }} id={formId} autoComplete="off">
                      <MDBox my={6}>
                        <MDBox mb={6}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} lg={6}>
                              <MDTypography variant="h4" fontWeight="medium">
                                {t("design.tabs.general")}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <MDBox display="flex" justifyContent="flex-end">
                                <MDButton
                                  disabled={!dirty || isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="info"
                                >
                                  {t("form.validate")}
                                  {isSubmitting && (
                                    <CircularProgress
                                      size={24}
                                      sx={{
                                        color: "inherit",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                      }}
                                    />
                                  )}
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={12}>
                            <GeneralUpdate formData={formData} />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("alert.errorMsg.errorGetData")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {renderAlertUpdate}
      {renderAlertErrorUpdate}
    </MDBox>
  );
};

export default CustomeGeneral;
