import * as Yup from "yup";
import checkout from "pages/create-app/schemas/form";
import i18next from "i18n";

const {
  formField: { name, email, activity, description, templateId },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(i18next.t(name.errorMsgKey)),
    [email.name]: Yup.string()
      .required(i18next.t(email.errorMsgKey))
      .email(i18next.t(email.invalidMsgKey)),
    [activity.name]: Yup.string().required(i18next.t(activity.errorMsgKey)),
    [description.name]: Yup.string().required(i18next.t(description.errorMsgKey)),
  }),
  Yup.object().shape({
    [templateId.name]: Yup.string().required(i18next.t(templateId.errorMsgKey)),
  }),
];

export default validations;
