import { IProduct } from "pages/element/element.type";

export enum IStatusOrder {
  PENDING = "pending",
  INPROGRESS = "inProgress",
  DONE = "done",
}

export enum IStatusReservation {
  PENDING = "pending",
  CANCEL = "cancel",
  CONFIRM = "confirm",
  REFUSE = "refuse",
}

export interface IEvent extends IReservation {
  groupId: string;
  allDay: boolean;
  daysOfWeek: string;
  startTimeendTimestartRecur: string;
  endRecur: string;
  url: string;
  interactive: boolean;
  className: string;
  editable: boolean;
  startEditable: string;
  durationEditable: string;
  resourceEditableresourceId: string;
  resourceIds: string;
  display: string;
  overlap: string;
  constraint: string;
  color: string;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  duration: string;
  startRecur: Date;
  extendedProps: any;
}

export interface IReservation {
  _id: string;
  id: string;
  productId: IProduct;
  start: string;
  end: string;
  title: string;
  startTime: Date;
  endTime: Date;
  email: string;
  name: string;
  message: string;
  tel: string;
  status: IStatusReservation;
}

export enum ETypeAction {
  CREATE = "create",
  UPDATE = "update",
}
