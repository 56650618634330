import { CircularProgress, Icon } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import MockupProduct from "pages/element/components/MockupProduct";
//import ProductUpdateInfo from "pages/product/components/ProductUpdateInfo";
import { ProductUpdateInfoAction } from "pages/element/components/ProductUpdateAction";
import ProductUpdateInfo from "pages/element/components/ProductUpdateInfo";
import form from "pages/element/schemas/product/form";
import { validationsUpdate } from "pages/element/schemas/product/validations";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteProduct,
  useGetAllAttribute,
  useGetAllProduct,
  useGetAllSubCategory,
  useGetProductById,
  useUpdateProduct,
} from "../element.hook";
import { EMenuElement } from "../element.type";

const EditProduct = (): JSX.Element => {
  const { t } = useTranslation();
  const { formId, formField } = form;
  const deleteProduct = useDeleteProduct();
  const updateProduct = useUpdateProduct();
  const [showError, setShowError] = useState(false);
  const { data: listeProducts } = useGetAllProduct();
  const [openAlertUpdate, setOpenAlertUpdate] = useState(false);
  const productIdUrl = new URLSearchParams(location.search).get("id");
  const [productId, setProductId] = useState(productIdUrl);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const { data: attributesData, isLoading: isLoadingAttribute } = useGetAllAttribute();
  const { data: productData, isLoading: isLoadingProduct } = useGetProductById(productId);

  const { data: subCategoriesData, isLoading: isLoadingSubCategories } = useGetAllSubCategory();
  const {
    name,
    title,
    category,
    price,
    description,
    image,
    attributes,
    inCarousel,
    productActionType,
    link,
    isMultipleReservation,
    isAutomaticConfirmation,
    durationDays,
    durationHours,
    durationMinutes,
    typeElement,
  } = formField;
  const [tabValue, setTabValue] = useState<number>(0);
  const [renderedTabs, setRenderedTabs] = useState<{ [key: number]: boolean }>({
    0: true, // Rendre le premier onglet actif dès le début
  });
  const [currentImage, setCurrentImage] = useState(productData?.mediaId?.url);
  const [initialValues, setInitialValues] = useState({
    [name.name]: productData?.name,
    [title.name]: productData?.title,
    [category.name]: productData?.subCategoryId,
    [price.name]: productData?.price,
    [description.name]: productData?.description,
    [image.name]: productData?.mediaId?.url,
    [attributes.name]: productData?.attributes,
    [inCarousel.name]: productData?.inCarousel,
    [productActionType.name]: productData?.redirection?.name || "None",
    [typeElement.name]: productData?.typeElement,
    [link.name]: productData?.redirection?.link,
    [isMultipleReservation.name]: productData?.reservation?.isMultipleReservation,
    [isAutomaticConfirmation.name]: productData?.reservation?.isAutomaticConfirmation,
    [durationDays.name]: productData?.reservation?.duration?.days || 0,
    [durationHours.name]: productData?.reservation?.duration?.hours || 0,
    [durationMinutes.name]: productData?.reservation?.duration?.minutes || 0,
  });

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!productData);
    }, 2000);
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      if (values.file) values.file = currentImage;

      await updateProduct.mutateAsync({ productId: productData?._id, data: values });
      resetForm({ values });
      setOpenAlertUpdate(true);
    } catch (error: any) {
      setErrorAlertTitle(t("alert.update.titleError"));
      setErrorAlertContent(t("alert.update.contentError", { errorMsg: error.message }));
    }
  };

  const renderAlertUpdate = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.update.title")}
      content={t("alert.update.content")}
      open={openAlertUpdate}
      onClose={() => setOpenAlertUpdate(false)}
      close={() => setOpenAlertUpdate(false)}
      bgWhite
    />
  );

  const renderAlertErrorUpdate = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  // Fonction pour obtenir l'index du produit actuellement sélectionné
  const getCurrentProductIndex = () => {
    return listeProducts.findIndex((item) => item._id === productId);
  };

  // Fonction pour passer au produit suivant
  const handleNextProduct = () => {
    if (!listeProducts) return;
    const currentIndex = getCurrentProductIndex();
    if (currentIndex < listeProducts.length - 1) {
      const nextProductId = listeProducts[currentIndex + 1]._id;
      setProductId(nextProductId);
    }
  };

  // Fonction pour passer au produit précédent
  const handlePreviousProduct = () => {
    if (!listeProducts) return;
    const currentIndex = getCurrentProductIndex();
    if (currentIndex > 0) {
      const previousProductId = listeProducts[currentIndex - 1]._id;
      setProductId(previousProductId);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      const deleteProductId = productId;
      await deleteProduct.mutateAsync(deleteProductId);
      handleNextProduct();
      if (deleteProductId === productId) handlePreviousProduct();
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleSetTabValue = (event: any, newValue: number) => {
    setTabValue(newValue);
    // Si l'onglet n'a pas encore été rendu, marquez-le comme rendu
    if (!renderedTabs[newValue]) {
      setRenderedTabs((prevRenderedTabs) => ({ ...prevRenderedTabs, [newValue]: true }));
    }
  };

  useEffect(() => {
    // Mettre à jour les valeurs initiales lorsque productData change
    if (!isLoadingProduct && productData) {
      setCurrentImage(productData?.mediaId?.url);
      setInitialValues({
        [name.name]: productData?.name,
        [title.name]: productData?.title,
        [category.name]: productData?.subCategoryId,
        [price.name]: productData?.price,
        [description.name]: productData?.description,
        [image.name]: productData?.mediaId?.url,
        [attributes.name]: productData?.attributes,
        [inCarousel.name]: productData?.inCarousel,
        [productActionType.name]: productData?.redirection?.name || "None",
        [typeElement.name]: productData?.typeElement,
        [link.name]: productData?.redirection?.link,
        [isMultipleReservation.name]: productData?.reservation?.isMultipleReservation || false,
        [isAutomaticConfirmation.name]: productData?.reservation?.isAutomaticConfirmation || false,
        [durationDays.name]: productData?.reservation?.duration?.days || 0,
        [durationHours.name]: productData?.reservation?.duration?.hours || 0,
        [durationMinutes.name]: productData?.reservation?.duration?.minutes || 0,
      });
    }
  }, [productData, isLoadingProduct]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        {isLoadingProduct || isLoadingSubCategories || isLoadingAttribute ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {productData && subCategoriesData ? (
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationsUpdate}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue, dirty, isSubmitting }) => {
                  const formData = {
                    values,
                    touched,
                    formField,
                    errors,
                    setFieldValue,
                    initialValues: initialValues,
                  };

                  return (
                    <Form id={formId} autoComplete="off">
                      <MDBox my={3}>
                        <MDBox mb={2}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid
                              item
                              xs={12}
                              lg={6}
                              style={{ display: "flex", justifyContent: "flex-start" }}
                            >
                              <MDTypography variant="h4" fontWeight="medium">
                                {productData.name}
                              </MDTypography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={5}
                              style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                              <MDBox display="flex" justifyContent="flex-end">
                                <MDButton
                                  style={{ marginRight: 2 }}
                                  variant="gradient"
                                  color="info"
                                  onClick={handlePreviousProduct}
                                  iconOnly
                                >
                                  <Icon>arrow_back_ios</Icon>
                                </MDButton>
                                &nbsp;
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  onClick={handleNextProduct}
                                  iconOnly
                                >
                                  <Icon>arrow_forward_ios</Icon>
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <MDBox mt={2} mb={2}>
                          <Grid container>
                            <Grid item xs={12} sm={8} lg={4}>
                              <MDBox minWidth={350}>
                                <AppBar position="static">
                                  <Tabs
                                    orientation="horizontal"
                                    value={tabValue}
                                    onChange={handleSetTabValue}
                                  >
                                    <Tab key={0} label={"Informations"} />
                                    <Tab key={1} label={"Action"} />
                                  </Tabs>
                                </AppBar>
                              </MDBox>
                            </Grid>
                          </Grid>
                          <div>
                            {/* Conditionally render content based on the selected tab */}
                            <div
                              key={0}
                              style={{ display: tabValue === 0 ? "block" : "none" }}
                            ></div>
                            <div
                              key={1}
                              style={{ display: tabValue === 1 ? "block" : "none" }}
                            ></div>
                          </div>
                        </MDBox>
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={8}>
                            {tabValue == 0 ? (
                              <ProductUpdateInfo
                                subCategories={subCategoriesData}
                                formData={formData}
                                defaultImage={productData.mediaId?.url}
                                allAttributes={attributesData}
                                cropImage={(value: any) => setCurrentImage(value)}
                                handleDeleteProduct={handleDeleteProduct}
                              >
                                {
                                  <MDBox display="flex" justifyContent="flex-end">
                                    <MDButton
                                      disabled={!dirty || isSubmitting}
                                      type="submit"
                                      variant="gradient"
                                      color="info"
                                    >
                                      {t("form.validate")}
                                      {isSubmitting && (
                                        <CircularProgress
                                          size={24}
                                          sx={{
                                            color: "inherit",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                          }}
                                        />
                                      )}
                                    </MDButton>
                                  </MDBox>
                                }
                              </ProductUpdateInfo>
                            ) : (
                              <ProductUpdateInfoAction
                                subCategories={subCategoriesData}
                                formData={formData}
                                defaultImage={productData.mediaId?.url}
                                allAttributes={attributesData}
                                cropImage={(value: any) => setCurrentImage(value)}
                                handleDeleteProduct={handleDeleteProduct}
                                menu={EMenuElement.ACTIONS}
                              >
                                {
                                  <MDBox display="flex" justifyContent="flex-end">
                                    <MDButton
                                      disabled={!dirty || isSubmitting}
                                      type="submit"
                                      variant="gradient"
                                      color="info"
                                    >
                                      {t("form.validate")}
                                      {isSubmitting && (
                                        <CircularProgress
                                          size={24}
                                          sx={{
                                            color: "inherit",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                          }}
                                        />
                                      )}
                                    </MDButton>
                                  </MDBox>
                                }
                              </ProductUpdateInfoAction>
                            )}
                          </Grid>
                          <Grid item xs={12} lg={1}>
                            <MockupProduct
                              image={currentImage}
                              allAttributes={attributesData}
                              formData={formData}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("element.errorGetProduct")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </MDBox>
      {renderAlertUpdate}
      {renderAlertErrorUpdate}
      <Footer />
    </DashboardLayout>
  );
};

// export default EditProduct;
export default React.memo(EditProduct);
