import { Autocomplete, Box, Icon, Popper, styled } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import Modal from "components/Modal/modal";
import ModalCreateCategory from "pages/element/components/ModalCreateOrUpdateCat";
import { ISubCategory } from "pages/element/element.type";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const StyledPopper = styled(Popper)({
  [`&`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
      listStyle: "none",
      marginBottom: "30px",
    },
  },
});

interface props {
  formData: any;
  subCategories: ISubCategory[];
}

const AddCategory = ({ formData, subCategories }: props): JSX.Element => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const modalCreateCategoryRef = useRef(null);
  const [openAlertCreateCategory, setOpenAlertCreateCategory] = useState(false);
  const [errorAlertTitleCategory, setErrorAlertTitleCategory] = useState<string | null>(null);
  const [errorAlertContentCategory, setErrorAlertContentCategory] = useState<string | null>(null);
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { category } = formField;
  const { subCategoryId: categoryV } = values;

  const defaultSubCategoryId = categoryV?._id || categoryV;
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    subCategories?.find((subCat) => subCat._id === defaultSubCategoryId)?.name || ""
  );

  const optionsSubCategories = subCategories?.map((subCategory) => subCategory.name) || [];

  const handleCategoryChange = (e: React.ChangeEvent<object>, value: string | null) => {
    const subCategoryId = value
      ? subCategories?.find((subCat) => subCat.name === value)?._id
      : null;
    setFieldValue(category.name, subCategoryId);
    setSelectedSubCategory(value || "");
  };

  const handleValidSubmit = async () => {
    if (modalCreateCategoryRef.current) {
      return await modalCreateCategoryRef.current.submitForm();
    }
  };

  useEffect(() => {
    const subCategoryId = categoryV?._id || categoryV;
    setSelectedSubCategory(
      subCategories?.find((subCat) => subCat._id === subCategoryId)?.name || ""
    );
  }, [categoryV]);

  const renderCreateCategory = (
    <Modal
      title={t("category.modal.create.title")}
      openModal={openModal}
      body={
        <ModalCreateCategory
          onFormSubmit={() => {
            setOpenModal(false);
            setOpenAlertCreateCategory(true);
          }}
          onError={(error) => {
            setErrorAlertTitleCategory(t("alert.create.titleError"));
            if (error.statusCode === 409) {
              setErrorAlertContentCategory(
                t("alert.create.contentError", { errorMsg: t("alert.errorMsg.duplicate") })
              );
            } else {
              setErrorAlertContentCategory(
                t("alert.create.contentError", { errorMsg: t("alert.errorMsg.refresh") })
              );
            }
          }}
          ref={modalCreateCategoryRef}
        />
      }
      handleValid={handleValidSubmit}
      handleClose={() => setOpenModal(false)}
    />
  );

  const renderAlertCreateCategory = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.create.title")}
      content={t("alert.create.content")}
      open={openAlertCreateCategory}
      onClose={() => setOpenAlertCreateCategory(false)}
      close={() => setOpenAlertCreateCategory(false)}
      bgWhite
    />
  );

  const renderAlertErrorCategory = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitleCategory}
      content={errorAlertContentCategory}
      open={!!errorAlertContentCategory}
      onClose={() => setErrorAlertContentCategory(null)}
      close={() => setErrorAlertContentCategory(null)}
      bgWhite
    />
  );

  return (
    <>
      {subCategories && (
        <Autocomplete
          value={selectedSubCategory}
          options={["ADD category", ...optionsSubCategories]}
          onChange={handleCategoryChange}
          PopperComponent={StyledPopper}
          renderOption={(props, option) => {
            if (option === "ADD category") {
              return (
                <Box
                  component="li"
                  {...props}
                  onClick={() => setOpenModal(true)}
                  style={{
                    borderRadius: "0%",
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    padding: "0",
                    zIndex: "999",
                  }}
                >
                  <MDButton size="small" variant="text" color="success" style={{ width: "100%" }}>
                    <Icon>add</Icon>&nbsp; {t("category.addCategory")}
                  </MDButton>
                </Box>
              );
            }
            return (
              <Box component="li" {...props}>
                {option}
              </Box>
            );
          }}
          renderInput={(params) => (
            <FormField
              {...params}
              variant="standard"
              type={category.type}
              label={t("form.category")}
              name={category.name}
              placeholder={category.placeholder}
              error={errors.category && touched.category}
              success={categoryV?.length > 0 && !errors.category}
            />
          )}
        />
      )}
      {renderCreateCategory}
      {renderAlertErrorCategory}
      {renderAlertCreateCategory}
    </>
  );
};

export default AddCategory;
