import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MockupCard from "components/MockupPhone/MockupCard";
import { ISection } from "pages/design/design.type";
import { useGetProductTop6 } from "pages/element/element.hook";
import { memo } from "react";
import defaultImage from "assets/images/bg-sign-up-cover.jpeg";

interface Props {
  section?: ISection;
}

const MockupSectionProduct = memo(({ section }: Props): JSX.Element => {
  const { data, isLoading } = useGetProductTop6();
  const custome = {
    bgColor: section?.bgColor || "#ffffff",
  };

  return (
    <>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="40vh">
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <MDBox bgColor={custome.bgColor} pl={1} pt={2} pb={2}>
          <Grid container rowSpacing={1} columnSpacing={-1}>
            {data.map((element, index) => {
              const isLandscape = index % 3 === 2;
              const data = { title: element.title, image: element.mediaId?.url || defaultImage };
              return (
                <Grid item xs={isLandscape ? 12 : 6} key={index}>
                  <MockupCard isLandscape={isLandscape} data={data} />
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
      )}
    </>
  );
});

export default MockupSectionProduct;
