import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import qrCodeIos from "assets/images/qrCode-hopelappTool-ios.png";
import qrCodeAndroid from "assets/images/qrCode-hopelappTool-android.png";
import { Grid, Link } from "@mui/material";
import { config } from "config/config";
interface Props {
  code: string;
}

const ModalDescription = ({ code }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {t("createApp.modalDescription.text1")}
        </MDTypography>
      </MDBox>

      <MDBox component="ul" m={0} pl={4} mb={2}>
        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1} pt={2}>
          <MDTypography
            variant="body2"
            color="text"
            fontWeight="regular"
            verticalAlign="middle"
            dangerouslySetInnerHTML={{
              __html: t("createApp.modalDescription.text2"),
            }}
          />
        </MDBox>
        <Grid container spacing={8} justifyContent="center" alignItems="center" pt={2}>
          <Grid item xs={12} sm={6}>
            <Link href={config.hopelapp.hopelappToolLinkIos} target="_blank">
              <MDBox
                component="img"
                src={qrCodeIos}
                alt="Qr code Hopelapp Tool"
                borderRadius="lg"
                shadow="md"
                height="8rem"
                sx={{ objectFit: "cover" }}
              />
              <MDTypography variant="body2" fontWeight="bold" color="text">
                IOS
              </MDTypography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link href={config.hopelapp.hopelappToolLinkAndroid} target="_blank">
              <MDBox
                component="img"
                src={qrCodeAndroid}
                alt="Qr code Hopelapp Tool"
                borderRadius="lg"
                shadow="md"
                height="8rem"
                sx={{ objectFit: "cover" }}
              />
              <MDTypography variant="body2" fontWeight="bold" color="text">
                Android
              </MDTypography>
            </Link>
          </Grid>
        </Grid>

        <MDBox component="li" color="text" fontSize="1.25rem" lineHeight={1} pt={2}>
          <MDTypography
            variant="body2"
            color="text"
            fontWeight="regular"
            verticalAlign="middle"
            dangerouslySetInnerHTML={{
              __html: t("createApp.modalDescription.text3", { code }),
            }}
          />
        </MDBox>
      </MDBox>

      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {t("createApp.modalDescription.text4")}
        </MDTypography>
      </MDBox>
    </>
  );
};

export default ModalDescription;
