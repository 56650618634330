import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

const ModalAccessDenied = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <MDBox mt={3} mb={1} ml={0.5}>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {t("createApp.modalAccessDenied.text1")}
        </MDTypography>
      </MDBox>
    </>
  );
};

export default ModalAccessDenied;
