import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import AddAttribute from "pages/element/components/AddAttribute";
import { IAttribute } from "pages/element/element.type";

interface props {
  formData: any;
  allAttributes: IAttribute[];
}

const ProductCreateDescription = ({ formData, allAttributes }: props): JSX.Element => {
  const { formField, values, setFieldValue } = formData;
  const { description, attributes } = formField;
  const { attributes: attributesV } = values;

  return (
    <MDBox>
      <MDTypography variant="h5">Element Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
            <MDEditor
              value={(content) => setFieldValue(description.name, content)}
              maxLength={1000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AddAttribute
              currentAttributes={attributesV}
              allAttributes={allAttributes}
              onChange={(newAttributes) => setFieldValue(attributes.name, newAttributes)}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default ProductCreateDescription;
