import api from "config/axios.config";
import { Design, ETypeDesign } from "./design.type";
import { IClient } from "types/client.type";

interface Props {
  clientId: string;
  typeDesign: ETypeDesign;
  data?: Design;
  select?: string;
}

export async function updateDesign({
  clientId,
  typeDesign,
  data,
  select,
}: Props): Promise<IClient> {
  return api.patch(
    `clients/${clientId}/design/${typeDesign}${select ? `?select=${select}` : ""}`,
    data
  );
}
