import MDBox from "components/MDBox";
import MockupPhone from "components/MockupPhone/MockPhone";
import { memo } from "react";

interface Props {
  formData: any;
}

const MockupPhoneNavigation = memo(({ formData }: Props): JSX.Element => {
  const { values } = formData;

  const { bgColor: bgColorV, textColor: textColorV, activeBgColor: activeBgColorV } = values;

  const styleNav = {
    bgColor: bgColorV,
    textColor: textColorV,
    activeBgColor: activeBgColorV,
  };

  return (
    <MockupPhone styleNavigation={styleNav}>
      <MDBox></MDBox>
    </MockupPhone>
  );
});

export default MockupPhoneNavigation;
