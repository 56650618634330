import api from "config/axios.config";
import { IEvent, IReservation, IStatusReservation } from "./reservation.type";

interface PropsReservation {
  clientId: string;
  reservationId: string;
  data?: { status: IStatusReservation };
}

export const getAllReservations = (clientId: string): Promise<IReservation[]> => {
  return api.get(`/clients/${clientId}/reservation`);
};

export const deleteReservation = ({
  clientId,
  reservationId,
}: PropsReservation): Promise<IReservation> => {
  return api.delete(`/clients/${clientId}/reservation/${reservationId}`);
};

export const updateReservation = ({
  clientId,
  reservationId,
  data,
}: PropsReservation): Promise<IReservation> => {
  return api.patch(`clients/${clientId}/reservation/${reservationId}`, data);
};

export const getAllEvents = (clientId: string): Promise<IEvent[]> => {
  return api.get(`/clients/${clientId}/events`);
};
