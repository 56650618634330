import * as React from "react";
import { useEffect, useRef, useState } from "react";

import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Navigation from "components/NavigationPage/Navigation";
import { useTranslation } from "react-i18next";
import Appointment from "pages/appointment";
import Order from "pages/order";
import Rent from "pages/rent";
import { useGetClientById } from "pages/client/client.hook";
import { SELECT_FEATURES } from "types/client.type";
import { EFeatures } from "types/features.type";

const Index = () => {
  const { t } = useTranslation();

  // #DOC Init feature in this page
  const featureUsedObj = new Map();
  featureUsedObj.set(EFeatures.BOOKINGAPPOINTMENT, <Appointment />);
  featureUsedObj.set(EFeatures.RENT, <Rent />);

  const { data, isLoading } = useGetClientById(SELECT_FEATURES);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {data ? (
        <Navigation
          stickyNavbar={true}
          tabs={[
            { label: t("order.tabs.order"), content: <Order />, mode: "inside" },
            ...Object.entries(data.features)
              .filter((feat) => {
                return [...featureUsedObj.keys()]
                  .filter((x) => feat[1].enabled)
                  .includes(feat[0] as EFeatures);
              })
              .map((feat) => {
                return {
                  label: t(`appointment.tabs.${feat[0]}`),
                  content: featureUsedObj.get(feat[0] as EFeatures),
                  mode: "inside",
                };
              }),
          ]}
        >
          <Footer />
        </Navigation>
      ) : null}
    </DashboardLayout>
  );
};

export default Index;
