import checkout from "pages/create-app/schemas/form";

const {
  formField: { name, email, activity, description, templateId },
} = checkout;

const initialValues = {
  [name.name]: "",
  [email.name]: "",
  [activity.name]: "",
  [description.name]: "",
  [templateId.name]: "",
};

export default initialValues;
