import Navigation from "components/NavigationPage/Navigation";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomeCard from "pages/design/Pages/CustomeCard";
import CustomeHomePage from "pages/design/Pages/CustomeHomePage";
import CustomeNavigation from "pages/design/Pages/CustomeNavigation";
import { useTranslation } from "react-i18next";
import CustomeGeneral from "pages/design/Pages/CustomeGeneral";

const Index = () => {
  const { t } = useTranslation();

  const tabs = [
    { label: t("design.tabs.general"), content: <CustomeGeneral />, mode: "inside" },
    { label: t("design.tabs.navigation"), content: <CustomeNavigation />, mode: "inside" },
    {
      label: t("design.tabs.card"),
      content: <CustomeCard />,
      mode: "inside",
    },
    {
      label: t("design.tabs.homePage"),
      content: <CustomeHomePage />,
      mode: "inside",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Navigation stickyNavbar={true} tabs={tabs}>
        <Footer />
      </Navigation>
    </DashboardLayout>
  );
};

export default Index;
