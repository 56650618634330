import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { ISection } from "pages/design/design.type";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  section?: ISection;
}

const MockupSectionSearchBar = memo(({ section }: Props): JSX.Element => {
  const { t } = useTranslation();
  const custome = {
    bgColor: section?.bgColor || "#ffffff",
    textColor: section?.textColor || "#000000",
  };

  return (
    <MDBox p={2} bgColor={custome.bgColor}>
      <MDInput
        placeholder={`${t("form.search")}...`}
        size="small"
        sx={{
          "& input::placeholder": {
            color: custome.textColor,
            fontSize: "14px",
          },
          "& fieldset": {
            borderColor: custome.textColor,
          },
        }}
        InputProps={{
          startAdornment: (
            <Icon sx={{ color: custome.textColor }} fontSize="medium">
              search
            </Icon>
          ),
        }}
        fullWidth
      />
    </MDBox>
  );
});

export default MockupSectionSearchBar;
