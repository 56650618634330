import { Box } from "@mui/system";
import "assets/css/styles.css";
import arrowHopelapp from "assets/images/arrow-hopelapp.png";
import brandIconWhite from "assets/images/brands/icon-white.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";

interface Props {
  onClick: () => void;
}

const CommencerLayout = ({ onClick }: Props): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let fontSize = 100;

  if (screenWidth < 450) {
    fontSize = 50;
  } else if (screenWidth < 550) {
    fontSize = 90;
  } else fontSize = 100;

  return (
    <div className="container" onClick={onClick}>
      <div className="row" style={{ cursor: "pointer" }} onClick={onClick}>
        <Box>
          <MDTypography
            className="title bounced avenir"
            sx={{ fontSize, color: "#FFFFFF" }}
            variant="h3"
            fontWeight="regular"
          >
            C<div className="letter">o</div>mmencer
          </MDTypography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "90%",
            marginLeft: -10,
            marginTop: -2,
          }}
        >
          <MDBox
            component="img"
            src={arrowHopelapp}
            alt="Brand"
            width="18vh"
            style={{ marginLeft: "auto" }}
          />
        </Box>
        <Box
          sx={{
            marginTop: -2,
          }}
        >
          <MDBox component="img" src={brandIconWhite} alt="Brand" width="30vh" />
        </Box>
      </div>
    </div>
  );
};

// Declaring default props for CoverLayout
CommencerLayout.defaultProps = {
  coverHeight: "35vh",
};

export default CommencerLayout;
