import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, CircularProgress, Icon, IconButton, Theme, Toolbar } from "@mui/material";
import logoDefault from "assets/images/brands/logo-white.png";
import colors from "assets/theme-dark/base/colors";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetClientById } from "pages/client/client.hook";
import { ReactNode, memo, useState } from "react";
import { SELECT_DESIGN } from "types/client.type";

interface Props {
  bgColor: string;
  textColor: string;
  activeBgColor: string;
  children: ReactNode;
}

const MockupBarNavigation = memo(({ bgColor, textColor, activeBgColor, children }: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { gradients } = colors;
  const { data: currentDesign, isLoading: isLoadingCurrentDesign } =
    useGetClientById(SELECT_DESIGN);
  const navigationDesign = currentDesign?.design?.navigation;
  const designNav = {
    bgColor: bgColor || navigationDesign?.bgColor,
    textColor: textColor || navigationDesign?.textColor,
    activeBgColor: activeBgColor || navigationDesign?.activeBgColor,
  };

  return (
    <>
      <MDBox style={{ display: "flex", width: 150 }}>
        {menuOpen && (
          <MDBox
            sx={{
              backgroundImage: ({
                functions: { linearGradient, rgba },
                palette: { gradients },
              }: Theme) => `${linearGradient(gradients.default.main, gradients.default.state)}`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: "calc(100vh)", // Calcul de la hauteur du menu
              paddingTop: "30px",
              paddingLeft: "15px",
              paddingRight: "20px",
            }}
          >
            <MDBox display="flex" alignItems="center" mt={4}>
              <MDBox mr={1}>
                <MDAvatar src={currentDesign?.logo || logoDefault} alt={"icon-logo"} />
              </MDBox>
              <MDTypography variant="caption" width={78} fontWeight="bold" color="#272c3f">
                {currentDesign?.name}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={4}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor={designNav.activeBgColor}
                width={25}
                height={25}
                mr={2}
                color="#fff"
                style={{
                  borderRadius: 5,
                }}
              >
                <Icon fontSize="small">storefront</Icon>
              </MDBox>
              <MDTypography variant="overline" fontWeight="bold" color="#272c3f">
                Accueil
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={2}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor="#E6E7EB"
                width={25}
                height={25}
                mr={2}
                color="#272c3f"
                style={{
                  borderRadius: 5,
                }}
              >
                <Icon fontSize="small">person</Icon>
              </MDBox>
              <MDTypography variant="overline" color="#272c3f">
                Contact
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={2}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor="#E6E7EB"
                width={25}
                height={25}
                mr={2}
                color="#272c3f"
                style={{
                  borderRadius: 5,
                }}
              >
                <Icon fontSize="small">person</Icon>
              </MDBox>
              <MDTypography variant="overline" color="#272c3f">
                Information
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItems="center" mt={2}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgColor="#E6E7EB"
                width={25}
                height={25}
                mr={2}
                color="#272c3f"
                style={{
                  borderRadius: 5,
                }}
              >
                <Icon fontSize="small">settings</Icon>
              </MDBox>
              <MDTypography variant="overline" color="#272c3f">
                Paramètres
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
        {isLoadingCurrentDesign ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <div>
            <AppBar
              position="static"
              style={{
                width: "100%",
                backgroundColor: menuOpen ? gradients.default.main : designNav.bgColor,
                color: designNav.textColor,
                paddingTop: 40,
              }}
            >
              <Toolbar
                style={{
                  backgroundColor: designNav.bgColor,
                  color: designNav.textColor,
                  borderTopLeftRadius: menuOpen ? 10 : 0,
                  marginTop: menuOpen ? 20 : 0,
                  transition: "margin-top 0.5s ease-in-out",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <MenuIcon />
                </IconButton>
                <MDTypography variant="overline" color={designNav.textColor}>
                  {currentDesign?.name}
                </MDTypography>
              </Toolbar>
            </AppBar>
            {children}
          </div>
        )}
      </MDBox>
    </>
  );
});

export default MockupBarNavigation;
