import ResetCover from "pages/auth/ResetPassword";
import SignIn from "pages/auth/SignIn";
import SignUp from "pages/auth/SignUp";
import HopelappStore from "pages/hopelappStore";
import ThankYou from "pages/thank-you";
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";
import profilePicture from "assets/images/profil-default.png";
import { User } from "firebase/auth";
import VerifyEmail from "pages/auth/VerifyEmail";
import { Admin } from "types/admin.type";
import CreateApp from "pages/create-app/pages/Create-app";
import i18next from "i18n";
import Element from "pages/element";
import Dashboard from "pages/dashboard";
import TechnicalService from "pages/technicalService";
import Chat from "pages/chat";
import MyApp from "pages/myApp";
import Design from "pages/design";
import EditProduct from "pages/element/pages/EditProduct";
import AddProduct from "pages/element/pages/AddProduct";
import Logout from "pages/auth/logout";

const routes = (currentAdmin?: Admin, currentUserFirebase?: User) => [
  {
    type: "collapse",
    name: currentAdmin?.lastName,
    key: "brooklyn-alice",
    icon: (
      <MDAvatar
        src={currentUserFirebase?.photoURL || profilePicture}
        alt="Brooklyn Alice"
        size="sm"
      />
    ),
    collapse: [
      // {
      //   name: "Settings",
      //   key: "settings",
      //   route: "/settings",
      //   component: <DefaultPage />,
      //   privateRoute: true,
      // },
      {
        name: i18next.t("navbar.logout"),
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },
  // {
  //   type: "collapse",
  //   noCollapse: true,
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   name: "Dashboards",
  //   key: "dashboards",
  //   route: "/dashboards",
  //   component: <DefaultPage />,
  //   privateRoute: true,
  // },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">feed</Icon>,
    name: i18next.t("navbar.myApp"),
    key: "myApp",
    route: "/myApp",
    component: <MyApp />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">shopping_basket</Icon>,
    name: i18next.t("navbar.element"),
    key: "element",
    route: "/element",
    component: <Element />,
    privateRoute: true,
  },
  {
    noCollapse: true,
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    name: i18next.t("navbar.edit"),
    key: "element-edit",
    route: "/element/edit",
    component: <EditProduct />,
    privateRoute: true,
  },
  {
    noCollapse: true,
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    name: i18next.t("navbar.add"),
    key: "element-new",
    route: "/element/new",
    component: <AddProduct />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">inbox</Icon>,
    name: i18next.t("navbar.dashboard"),
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">view_in_ar</Icon>,
    name: i18next.t("navbar.design"),
    key: "design",
    route: "/design",
    component: <Design />,
    privateRoute: true,
  },
  // {
  //   type: "collapse",
  //   noCollapse: true,
  //   icon: <Icon fontSize="medium">chat</Icon>,
  //   name: i18next.t("navbar.chat"),
  //   key: "chat",
  //   route: "/chat",
  //   component: <Chat />,
  //   privateRoute: true,
  // },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">widgets</Icon>,
    name: i18next.t("navbar.store"),
    key: "store",
    route: "/store",
    component: <HopelappStore />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">live_help</Icon>,
    name: i18next.t("navbar.technical-support"),
    key: "technical-support",
    route: "/technical-support",
    component: <TechnicalService />,
    privateRoute: true,
  },

  {
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in",
            component: <SignIn />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up",
            component: <SignUp />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password",
            component: <ResetCover />,
          },
        ],
      },
      {
        name: "Thank you",
        key: "thank-you",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/thank-you",
            component: <ThankYou />,
          },
        ],
      },
      {
        name: "Verify email",
        key: "verify-email",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/verify-email",
            component: <VerifyEmail />,
            privateRoute: true,
          },
        ],
      },
    ],
  },
  {
    // type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    name: "Create App",
    key: "create-app",
    route: "/create/app",
    component: <CreateApp />,
    privateRoute: true,
  },
];

export default routes;
