import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useGetTemplate } from "pages/client/client.hook";
import { useState } from "react";

const Template = ({ formData }: any): JSX.Element => {
  const { formField, setFieldValue } = formData;
  const { templateId } = formField;

  const { data: templateData, isLoading } = useGetTemplate();
  const [selectTempalte, setSelectTemplate] = useState(templateData && templateData[0]?.code);

  const handleSetTemplate = (id: string) => {
    setFieldValue(templateId.name, id);
    setSelectTemplate(id);
  };

  const customButtonStyles = ({ functions: { pxToRem }, palette: { transparent } }: Theme) => ({
    width: pxToRem(165),
    height: pxToRem(333),
    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `solid ${transparent.main}`,
    },
  });

  const customBoxStyles = ({ functions: { pxToRem }, palette: { transparent } }: Theme) => ({
    height: pxToRem(325),
    borderRadius: "2%",
    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `solid ${transparent.main}`,
    },
  });

  const renderTemplate = templateData?.map(({ _id, name, template }) => {
    return (
      <Grid key={_id} item xs={12} sm={3} ml={2} mr={2}>
        <MDBox textAlign="center">
          <MDButton
            color="info"
            variant={selectTempalte === _id ? "contained" : "outlined"}
            onClick={() => handleSetTemplate(_id)}
            sx={customButtonStyles}
          >
            <MDBox component="img" src={template.images[0]} alt={name} sx={customBoxStyles} />
          </MDButton>
        </MDBox>
      </Grid>
    );
  });

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Template
      </MDTypography>
      <MDBox sx={{ height: "25rem", overflowY: "scroll" }}>
        <Grid container spacing={3} justifyContent="center" pt={1}>
          {renderTemplate}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default Template;
