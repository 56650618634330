import { Grid, Icon } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal, { EType } from "components/Modal/modal";
import DataTable from "examples/Tables/DataTable";
import ModalCreateOrUpdateCat from "pages/element/components/ModalCreateOrUpdateCat";
import ModalDelete from "pages/element/components/ModalDelete";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteCategory, useGetAllSubCategory } from "../element.hook";
import { ETypeAction, ISubCategory } from "../element.type";
import moment from "moment";

const ListeCategory = (): JSX.Element => {
  const { t } = useTranslation();
  moment.locale(t("lang"));
  const deleteCategory = useDeleteCategory();
  const modalCreateCategoryRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const { data, isLoading } = useGetAllSubCategory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [openAlertCreateCategory, setOpenAlertCreateCategory] = useState(false);
  const [selectCategory, setSelectCategory] = useState<ISubCategory | null>(null);
  const [typeAction, setTypeAction] = useState<ETypeAction>(ETypeAction.CREATE);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const [errorAlertTitleCategory, setErrorAlertTitleCategory] = useState<string | null>(null);
  const [errorAlertContentCategory, setErrorAlertContentCategory] = useState<string | null>(null);

  const dataTableCategory = {
    columns: [
      { Header: t("category.headerName"), accessor: "name", width: "75%" },
      {
        Header: t("category.headerUpdatedAt"),
        accessor: (row: any) => moment(row?.updatedAt).format("MM/DD/YYYY HH:mm"),
        width: "15%",
      },
      {
        Header: t("category.action"),
        accessor: "action",
        Cell: ({ row }: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <MDBox>
                <MDButton
                  variant="text"
                  color={"dark"}
                  onClick={() => handleActionUpdate(row.original)}
                >
                  <Icon>edit</Icon>
                </MDButton>
              </MDBox>
              <MDButton
                variant="text"
                color="error"
                onClick={() => handleActionDelete(row.original)}
              >
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          );
        },
      },
    ],
    rows: data,
  };

  const handleActionDelete = (subCategory: ISubCategory) => {
    setSelectCategory(subCategory);
    setOpenModalDelete(true);
  };

  const handleActionUpdate = (subCategory: ISubCategory) => {
    setSelectCategory(subCategory);
    setTypeAction(ETypeAction.UPDATE);
    setOpenModalCreate(true);
  };

  const handleCreate = () => {
    setSelectCategory(null);
    setTypeAction(ETypeAction.CREATE);
    setOpenModalCreate(true);
  };

  const handleDelete = async () => {
    try {
      await deleteCategory.mutateAsync(selectCategory?._id);
      setOpenModalDelete(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: selectCategory?.name }));
    }
  };

  const handleCloseDelete = () => setOpenModalDelete(false);

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={t("alert.delete.content", { item: selectCategory?.name })}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const renderAlertCreateCategory = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t(`alert.${typeAction}.title`)}
      content={t(`alert.${typeAction}.content`)}
      open={openAlertCreateCategory}
      onClose={() => setOpenAlertCreateCategory(false)}
      close={() => setOpenAlertCreateCategory(false)}
      bgWhite
    />
  );

  const renderAlertErrorCategory = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitleCategory}
      content={errorAlertContentCategory}
      open={!!errorAlertContentCategory}
      onClose={() => setErrorAlertContentCategory(null)}
      close={() => setErrorAlertContentCategory(null)}
      bgWhite
    />
  );

  const renderModalDelete = (
    <Modal
      title={t("element.modalDelete.title")}
      openModal={openModalDelete}
      body={<ModalDelete name={selectCategory?.name} />}
      handleValid={handleDelete}
      handleClose={handleCloseDelete}
      type={EType.delete}
    />
  );

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!data);
    }, 2000);
  };

  const handleValidSubmit = async () => {
    if (modalCreateCategoryRef.current) return await modalCreateCategoryRef.current.submitForm();
  };

  const renderCreateOrUpdateCat = (
    <Modal
      title={t(`category.modal.${typeAction}.title`)}
      openModal={openModalCreate}
      body={
        <ModalCreateOrUpdateCat
          onFormSubmit={() => {
            setOpenModalCreate(false);
            setOpenAlertCreateCategory(true);
          }}
          onError={(error) => {
            setErrorAlertTitleCategory(t(`alert.${typeAction}.titleError`));
            if (error.statusCode === 409) {
              setErrorAlertContentCategory(
                t(`alert.${typeAction}.contentError`, { errorMsg: t("alert.errorMsg.duplicate") })
              );
            } else {
              setErrorAlertContentCategory(
                t(`alert.${typeAction}.contentError`, { errorMsg: t("alert.errorMsg.refresh") })
              );
            }
          }}
          currentSubCategory={selectCategory}
          ref={modalCreateCategoryRef}
        />
      }
      handleValid={handleValidSubmit}
      handleClose={() => setOpenModalCreate(false)}
    />
  );

  return (
    <>
      <MDBox pt={1} pb={3}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {data ? (
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={7}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {t("category.titleTable")}
                      </MDTypography>
                      <MDTypography variant="button" color="text">
                        {t("category.descriptionTable")}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                      <MDButton variant="gradient" color="info" onClick={() => handleCreate()}>
                        <Icon>add</Icon>&nbsp; {t("category.addCategory")}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>

                <DataTable table={dataTableCategory} canSearch />
              </Card>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("element.errorGetProduct")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </MDBox>

      {renderModalDelete}
      {renderAlertDelete}
      {renderAlertError}
      {renderCreateOrUpdateCat}
      {renderAlertErrorCategory}
      {renderAlertCreateCategory}
    </>
  );
};

export default React.memo(ListeCategory);
