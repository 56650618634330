import { CircularProgress, Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDButton from "components/MDButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export enum EType {
  delete = "delete",
  confirm = "confirm",
}
interface Props {
  title: string;
  body: JSX.Element;
  openModal: boolean;
  handleClose: () => void;
  handleValid?: () => void;
  handleAction?: () => void;
  valid?: boolean;
  action?: boolean;
  actionLabel?: string;
  actionStyle?:
    | "light"
    | "default"
    | "dark"
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error";
  cancel?: boolean;
  type?: EType;
  fullScreen?: boolean;
}

export default function Modal({
  title,
  body,
  openModal,
  handleValid,
  handleClose,
  handleAction,
  action = false,
  valid = true,
  cancel = true,
  actionLabel,
  actionStyle,
  type,
  fullScreen = false,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isFullScreen = fullScreen || useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);

  const handleValidLoading = async () => {
    setIsLoading(true);
    await handleValid();
    setIsLoading(false);
  };

  const handleActionLoading = async () => {
    setIsLoading(true);
    await handleAction();
    setIsLoading(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={isFullScreen}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        {type === EType.delete ? (
          <DialogActions>
            <MDButton variant="contained" color="dark" onClick={handleClose}>
              <Icon>cancel</Icon>&nbsp;{t("form.cancel")}
            </MDButton>
            <MDButton
              variant="contained"
              color="error"
              onClick={handleValidLoading}
              disabled={isLoading}
            >
              <Icon>delete</Icon>&nbsp;{t("form.delete")}
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "inherit",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDButton>
          </DialogActions>
        ) : (
          <DialogActions>
            {cancel && (
              <Button
                style={{ position: cancel && action && valid ? "absolute" : "relative", left: "0" }}
                onClick={handleClose}
              >
                {t("form.close")}
              </Button>
            )}
            {action && (
              <MDButton
                onClick={handleActionLoading}
                color={actionStyle}
                autoFocus
                disabled={isLoading}
              >
                {actionLabel}
              </MDButton>
            )}
            {valid && (
              <MDButton
                variant="contained"
                color="success"
                onClick={handleValidLoading}
                disabled={isLoading}
              >
                {t("form.validate")}
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "inherit",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </MDButton>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
