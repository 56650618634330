import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import {
  createAttribute,
  createProduct,
  createSubCategory,
  deleteProduct,
  deleteCategory,
  getAllAttributes,
  getAllProducts,
  getAllSubCategory,
  getProductById,
  updateProduct,
  updateSubCategory,
  deleteAttribute,
  updateAttribute,
  getProductTop6,
  synchShopifyProducts,
} from "./element.service";
import {
  ETypeActionButtonProduct,
  ETypeElement,
  IAttribute,
  IAttributeCreate,
  IProduct,
  IProductCreate,
  IProductUpdate,
  ISubCategory,
} from "./element.type";

enum KeyQueryProduct {
  getAllProduct = "getAllProduct",
  getProductTop6 = "getProductTop6",
  getAllAttributes = "getAllAttributes",
  getAllSubCategory = "getAllSubCategory",
  getProductById = "getProductById",
  synchShopifyProducts = "synchShopifyProducts",
}

export const useGetAllProduct = (): UseQueryResult<IProduct[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryProduct.getAllProduct, clientId], () => {
    if (!clientId) return null;

    return getAllProducts(clientId);
  });
};

export const useGetProductTop6 = (): UseQueryResult<IProduct[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryProduct.getProductTop6, clientId], () => {
    if (!clientId) return null;

    return getProductTop6(clientId);
  });
};

export const useGetAllAttribute = (): UseQueryResult<IAttribute[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryProduct.getAllAttributes, clientId], () => {
    if (!clientId) return null;

    return getAllAttributes(clientId);
  });
};

export const useDeleteProduct = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (productId: string) => deleteProduct({ clientId, productId }),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllProduct, clientId],
        (prevProducts: IProduct[] | undefined) => {
          return prevProducts?.filter((element) => element._id !== element._id) || [];
        }
      );
    },
  });
};

export const useSyncShopifyAllProducts = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => synchShopifyProducts({ clientId }),
    onSuccess: async () => {
      queryClient.setQueryData(
        [KeyQueryProduct.synchShopifyProducts, clientId],
        (prevProducts: IProduct[] | undefined) => {
          return prevProducts?.filter((element) => element._id !== element._id) || [];
        }
      );
    },
  });
};

export const useDeleteCategory = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (subCategoryId: string) => deleteCategory({ clientId, subCategoryId }),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllSubCategory, clientId],
        (prevCategories: ISubCategory[]) => {
          return prevCategories?.filter((category) => category._id !== result._id) || [];
        }
      );
    },
  });
};

export const useDeleteAttribute = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (attributeId: string) => deleteAttribute({ clientId, attributeId }),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllAttributes, clientId],
        (prevAttributes: IAttribute[]) => {
          return prevAttributes?.filter((attribute) => attribute._id !== result._id) || [];
        }
      );
    },
  });
};

export const useGetProductById = (productId: string): UseQueryResult<IProduct> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryProduct.getProductById, clientId, productId], () => {
    if (!clientId || !productId) return null;

    return getProductById({ clientId, productId });
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  const { clientId } = useContext(AuthContext);

  return useMutation<IProduct, Error, { productId: string; data: IProductUpdate }>({
    mutationFn: async ({ productId, data }) => {
      if (!productId || !data) return null;
      const formData = new FormData();
      if (data.name) formData.append("name", data.name);
      if (data.title) formData.append("title", data.title);
      if (data.file) formData.append("file", data.file);
      if (data.subCategoryId) formData.append("subCategoryId", data.subCategoryId);
      if (data.price) formData.append("price", data.price.toString());
      if (data.description) formData.append("description", data.description);
      if (data.attributes) formData.append("attributes", JSON.stringify(data.attributes));
      if (data.link)
        formData.append(
          "redirection",
          JSON.stringify({
            name: data.productActionType,
            link:
              data.link === "" || data.productActionType === "None"
                ? null
                : encodeURIComponent(data.link),
          })
        );
      if (Object.values(ETypeElement).includes(data.productActionType as any)) {
        formData.append("typeElement", data.productActionType?.toString());
      } else {
        formData.append("typeElement", ETypeElement.PRODUCT);
      }
      if (data.productActionType === ETypeActionButtonProduct.APPOINTMENT) {
        formData.append(
          "reservation",
          JSON.stringify({
            isMultipleReservation: JSON.parse(data.isMultipleReservation),
            isAutomaticConfirmation: JSON.parse(data.isAutomaticConfirmation),
            duration: {
              days: data.durationDays,
              hours: data.durationHours,
              minutes: data.durationMinutes,
            },
          })
        );
      }

      if (data.inCarousel) formData.append("inCarousel", data.inCarousel?.toString());

      return updateProduct({ clientId, productId, data: formData });
    },
    onSuccess: (result, variables) => {
      const subCategoryId =
        typeof result.subCategoryId !== "string" ? result.subCategoryId._id : result.subCategoryId;

      queryClient.setQueryData([KeyQueryProduct.getProductById, clientId, variables.productId], {
        ...result,
        subCategoryId,
      });
      queryClient.setQueryData([KeyQueryProduct.getAllProduct, clientId], (oldData: IProduct[]) => {
        if (!oldData) return oldData;

        const updatedProducts = oldData.map((element) => {
          if (element._id === variables.productId) return { ...element, ...result };
          return element;
        });

        return updatedProducts;
      });
      queryClient.setQueryData(
        [KeyQueryProduct.getProductTop6, clientId],
        (oldData: IProduct[]) => {
          if (!oldData) return oldData;

          const updatedProducts = oldData.map((element) => {
            if (element._id === variables.productId) return { ...element, ...result };
            return element;
          });

          return updatedProducts;
        }
      );
    },
  });
};

export const useUpdateSubCategory = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ subCategoryId, name }: { subCategoryId: string; name: string }) => {
      return updateSubCategory({ clientId, subCategoryId, data: { name } });
    },
    onSuccess: async (result, variables) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllSubCategory, clientId],
        (oldData: IProduct[]) => {
          if (!oldData) return oldData;

          const updatedSubCategories = oldData.map((subCategory) => {
            if (subCategory._id === variables.subCategoryId) return { ...subCategory, ...result };
            return subCategory;
          });

          return updatedSubCategories;
        }
      );
    },
  });
};

export const useGetAllSubCategory = (): UseQueryResult<ISubCategory[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryProduct.getAllSubCategory, clientId], () => {
    if (!clientId) return null;

    return getAllSubCategory(clientId);
  });
};

export const useCreateSubCategory = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (name: string) => createSubCategory({ clientId, data: { name } }),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllSubCategory, clientId],
        (prevSubCategories: ISubCategory[] | undefined) => [...prevSubCategories, result]
      );
    },
  });
};

export const useCreateAttribute = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation<IAttribute, Error, { data: IAttributeCreate }>({
    mutationFn: async ({ data }) => {
      const formData = new FormData();
      if (data.title) formData.append("name", data.title);
      if (data.subTitle) formData.append("value", data.subTitle);
      if (data.file) formData.append("file", data.file);
      return createAttribute({ clientId, data: formData });
    },

    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllAttributes, clientId],
        (prevAttributes: IAttribute[] | undefined) => [...prevAttributes, result]
      );
    },
  });
};

export const useUpdateAttribute = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ attributeId, data }: { attributeId: string; data: IAttributeCreate }) => {
      const formData = new FormData();
      if (data.title) formData.append("name", data.title);
      if (data.subTitle) formData.append("value", data.subTitle);
      if (data.file) formData.append("file", data.file);
      return updateAttribute({ clientId, attributeId, data: formData });
    },
    onSuccess: async (result, variables) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllAttributes, clientId],
        (oldData: IAttribute[]) => {
          if (!oldData) return oldData;

          const updatedAttributes = oldData.map((attribute) => {
            if (attribute._id === variables.attributeId) return { ...attribute, ...result };
            return attribute;
          });

          return updatedAttributes;
        }
      );
    },
  });
};

export const useCreateProduct = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation<IProduct, Error, { data: IProductCreate }>({
    mutationFn: async ({ data }) => {
      if (!data) return null;

      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("title", data.title);
      formData.append("file", data.file);
      formData.append("subCategoryId", data.subCategoryId);
      formData.append("price", data.price.toString());
      formData.append("description", data.description);
      if (data.attributes) formData.append("attributes", JSON.stringify(data.attributes));
      return createProduct({ clientId, data: formData });
    },

    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryProduct.getAllProduct, clientId],
        (prevProduct: IProduct[]) => {
          if (!prevProduct) return prevProduct;

          return [...prevProduct, result];
        }
      );
    },
  });
};
