import { Grid, Icon } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal, { EType } from "components/Modal/modal";
import DataTable from "examples/Tables/DataTable";
import ModalDelete from "pages/element/components/ModalDelete";
import ModalConfirmShopifySync from "pages/element/components/ModalConfirmShopifySync";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteProduct, useGetAllProduct, useSyncShopifyAllProducts } from "../element.hook";
import { IProduct } from "../element.type";
import moment from "moment";
import verifyLicence from "utils/verifyLicence";
import { EFeatures } from "types/features.type";
import { useGetClientById } from "pages/client/client.hook";
import { SELECT_FEATURES } from "types/client.type";

const Product = (): JSX.Element => {
  const { t } = useTranslation();
  moment.locale(t("lang"));
  const navigate = useNavigate();
  const deleteProduct = useDeleteProduct();
  const syncShopify = useSyncShopifyAllProducts();
  const { data: dataFeatures } = useGetClientById(SELECT_FEATURES);
  const { data, isLoading } = useGetAllProduct();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalSyncShopify, setOpenModalSyncShopify] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [openAlertSyncShopify, setOpenAlertSyncShopify] = useState(false);
  const [selectProduct, setSelectProduct] = useState<IProduct | null>(null);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);

  const dataTableProduct = {
    columns: [
      {
        Header: t("element.headerName"),
        accessor: "name",
        width: "25%",
        Cell: ({ row }: any) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/element/edit?id=${row.original._id}`)}
          >
            {row.original.name}
          </span>
        ),
      },
      {
        Header: t("element.headerCategory"),
        accessor: (row: any) => row.subCategoryId?.name || row.sub_categories_doc?.name || "",
        width: "25%",
      },
      { Header: t("element.headerPrice"), accessor: "price", width: "15%" },
      {
        Header: t("element.headerUpdatedAt"),
        accessor: (row: any) => moment(row?.updatedAt).format("MM/DD/YYYY HH:mm"),
        width: "15%",
      },
      {
        Header: t("element.action"),
        accessor: "action",
        Cell: ({ row }: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <MDBox>
                <MDButton
                  variant="text"
                  color={"dark"}
                  onClick={() => navigate(`/element/edit?id=${row.original._id}`)}
                >
                  <Icon>edit</Icon>
                </MDButton>
              </MDBox>
              <MDButton variant="text" color="error" onClick={() => handleAction(row.original)}>
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          );
        },
      },
    ],
    rows: data,
  };

  const handleAction = (product: IProduct) => {
    setSelectProduct(product);
    setOpenModalDelete(true);
  };

  const handleShopifySync = async () => {
    try {
      await syncShopify.mutateAsync();
      setOpenModalSyncShopify(false);
      setOpenAlertSyncShopify(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.sync.titleError"));
      setErrorAlertContent(t("alert.sync.contentError"));
    }
  };

  const handleDelete = async () => {
    try {
      await deleteProduct.mutateAsync(selectProduct?._id);
      setOpenModalDelete(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: selectProduct?.name }));
    }
  };

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalSyncShopify(false);
  };

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={t("alert.delete.content", { item: selectProduct?.name })}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertSyncShopify = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.sync.title")}
      content={t("alert.sync.content")}
      open={openAlertSyncShopify}
      onClose={() => setOpenAlertSyncShopify(false)}
      close={() => setOpenAlertSyncShopify(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const renderModalDelete = (
    <Modal
      title={t("element.modalDelete.title")}
      openModal={openModalDelete}
      body={<ModalDelete name={selectProduct?.name} />}
      handleValid={handleDelete}
      handleClose={handleClose}
      type={EType.delete}
    />
  );

  const renderModalShopify = (
    <Modal
      title={t("element.modalSyncShopify.title")}
      openModal={openModalSyncShopify}
      body={<ModalConfirmShopifySync />}
      handleValid={handleShopifySync}
      handleClose={handleClose}
      type={EType.confirm}
    />
  );

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!data);
    }, 2000);
  };

  return (
    <>
      <MDBox pt={1} pb={3}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {data ? (
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={7}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {t("element.titleTable")}
                      </MDTypography>
                      <MDTypography variant="button" color="text">
                        {t("element.descriptionTable")}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => navigate("/element/new")}
                      >
                        <Icon>add</Icon>&nbsp; {t("element.addElement")}
                      </MDButton>{" "}
                      {verifyLicence(dataFeatures, EFeatures.SHOPIFY) ? (
                        <MDButton
                          variant="gradient"
                          color="warning"
                          onClick={() => setOpenModalSyncShopify(true)}
                        >
                          <Icon>sync</Icon>&nbsp; {t("element.syncShopify")}
                        </MDButton>
                      ) : null}
                    </Grid>
                  </Grid>
                </MDBox>

                <DataTable table={dataTableProduct} canSearch />
              </Card>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("element.errorGetProduct")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </MDBox>

      {renderModalDelete}
      {renderAlertDelete}
      {renderAlertError}
      {renderModalShopify}
      {renderAlertSyncShopify}
    </>
  );
};

export default React.memo(Product);
