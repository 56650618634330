import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { AuthContext } from "context/auth-context/auth.context";
import Cookies from "js-cookie";
import { useGetClients } from "pages/client/client.hook";
import { useContext, useState } from "react";

const ClientSelector = () => {
  const { data, isLoading } = useGetClients();
  const { setClientId, clientId } = useContext(AuthContext);

  // const savedClientId = Cookies.get("selectedClientId");

  const changeClient = (selectedClientId: string) => {
    Cookies.set("selectedClientId", selectedClientId, { expires: 365 }); // Sauvegarde la langue dans un cookie
    setClientId(selectedClientId);
    window.location.reload();
  };

  return (
    <>
      {!isLoading && data && (
        <Autocomplete
          options={data}
          getOptionLabel={(option) => option.name}
          value={data.find((client) => client?._id === clientId)}
          defaultValue={data.find((client) => client?._id === clientId)}
          onChange={(e, value) => {
            if (value) changeClient(value._id);
          }}
          renderInput={(params) => <MDInput {...params} variant="standard" />}
        />
      )}
    </>
  );
};

export default ClientSelector;
