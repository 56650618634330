import { Grid } from "@mui/material";
import CropImage from "components/ImageCrop/CropImage";
import MDBox from "components/MDBox";
import MDDropzone from "components/MDDropzone";
import MDTypography from "components/MDTypography";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface props {
  formData: any;
  setImageCroped: any;
}

const ProductCreateMedia = ({ formData, setImageCroped }: props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, setFieldValue, values } = formData;
  const { file } = formField;
  const { file: fileV } = values;

  return (
    <MDBox>
      <MDTypography variant="h5">Element Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              {t("form.image")}
            </MDTypography>
            {useMemo(
              () => (
                <MDDropzone
                  options={{
                    addRemoveLinks: false,
                    maxFiles: 1,
                    acceptedFiles: "image/png, image/jpg, image/jpeg",
                  }}
                  onChange={(fileImage) => setFieldValue(file.name, fileImage)}
                />
              ),
              []
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text">
              {t("form.imagePosition")}
            </MDTypography>

            <CropImage
              image={fileV}
              onChange={(value: any) => setImageCroped(value)}
              // crop={crop}
              // zoom={zoom}
              // aspect={4 / 3}
              // onCropChange={setCrop}
              // onCropComplete={onCropComplete}
              // onZoomChange={setZoom}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default ProductCreateMedia;
