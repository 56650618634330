import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import { useGetClientById } from "pages/client/client.hook";
import CardUpdate from "pages/design/components/CardUpdate";
import MockupPhoneCard from "pages/design/components/MockupPhoneCard";
import form from "pages/design/schemas/card/form";
import validations from "pages/design/schemas/card/validations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SELECT_DESIGN } from "types/client.type";
import { useUpdateDesign } from "../design.hook";
import { ETypeDesign } from "../design.type";

const CustomeCard = (): JSX.Element => {
  const { t } = useTranslation();
  const { formId, formField } = form;
  const [showError, setShowError] = useState(false);
  const [openAlertUpdate, setOpenAlertUpdate] = useState(false);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const { type, bgColor, textColor, linkColor } = formField;
  const { data: currentDesign, isLoading: isLoadingCurrentDesign } =
    useGetClientById(SELECT_DESIGN);
  const cardDesign = currentDesign?.design?.card;
  const updateDesign = useUpdateDesign();

  const initialValues = {
    [type.name]: cardDesign?.type,
    [bgColor.name]: cardDesign?.bgColor,
    [textColor.name]: cardDesign?.textColor,
    [linkColor.name]: cardDesign?.linkColor,
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      await updateDesign.mutateAsync({
        typeDesign: ETypeDesign.CARD,
        select: SELECT_DESIGN,
        data: { [ETypeDesign.CARD]: values },
      });
      resetForm({ values });
      setOpenAlertUpdate(true);
    } catch (error: any) {
      setErrorAlertTitle(t("alert.update.titleError"));
      setErrorAlertContent(t("alert.update.contentError", { errorMsg: error.message }));
    }
  };

  const renderAlertUpdate = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.update.title")}
      content={t("alert.update.content")}
      open={openAlertUpdate}
      onClose={() => setOpenAlertUpdate(false)}
      close={() => setOpenAlertUpdate(false)}
      bgWhite
    />
  );

  const renderAlertErrorUpdate = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!currentDesign);
    }, 2000);
  };

  return (
    <MDBox mt={1} mb={5}>
      <Grid container spacing={1}>
        {isLoadingCurrentDesign ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {currentDesign ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, setFieldValue, dirty, isSubmitting }) => {
                  const formData = {
                    values,
                    touched,
                    formField,
                    errors,
                    setFieldValue,
                  };

                  return (
                    <Form id={formId} autoComplete="off">
                      <MDBox my={3}>
                        <MDBox mb={6}>
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} lg={6}>
                              <MDTypography variant="h4" fontWeight="medium">
                                {t("design.tabs.card")}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <MDBox display="flex" justifyContent="flex-end">
                                <MDButton
                                  disabled={!dirty || isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="info"
                                >
                                  {t("form.validate")}
                                  {isSubmitting && (
                                    <CircularProgress
                                      size={24}
                                      sx={{
                                        color: "inherit",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                      }}
                                    />
                                  )}
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                        <Grid container spacing={4}>
                          <Grid item xs={12} lg={8}>
                            <CardUpdate formData={formData} />
                          </Grid>
                          <Grid item xs={12} lg={3} width={500}>
                            <MockupPhoneCard formData={formData} />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("alert.errorMsg.errorGetData")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {renderAlertUpdate}
      {renderAlertErrorUpdate}
    </MDBox>
  );
};

export default CustomeCard;
