import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useGetClientById } from "pages/client/client.hook";
import Currency from "pages/myApp/components/settings/Currency";
import DeleteAccount from "pages/myApp/components/settings/DeleteAccount";
import Header from "pages/myApp/components/settings/Header";
import { SELECT_INFORMATION } from "types/client.type";
import QrCodeApp from "pages/myApp/components/settings/QrCodeApp";

const Settings = () => {
  const { data, isLoading } = useGetClientById(SELECT_INFORMATION);

  return (
    <MDBox mt={4}>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <Grid container spacing={3}>
          {/* <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid> */}
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header logo={data.logo} name={data.name} />
                </Grid>
                <Grid item xs={12}>
                  <QrCodeApp />
                </Grid>
                <Grid item xs={12}>
                  <Currency currency={data?.settings?.currency} />
                </Grid>
                <Grid item xs={12}>
                  <DeleteAccount />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
};

export default Settings;
