import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface Props {
  title: string;
  value: string;
}

const InfoBox = ({ title, value }: Props): JSX.Element => {
  return (
    <MDBox mb={2} display="flex" flexDirection="column">
      <MDBox>
        <MDTypography
          variant="caption"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox sx={{ marginTop: "-6px" }}>
        <MDTypography variant="body2" fontWeight="regular">
          {value}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default InfoBox;
