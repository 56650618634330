import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import bgImage from "assets/brand/bg-signIn.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import CoverLayout from "layouts/authentication/components/Layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { signInUser } from "services/firebase.service";
import * as Yup from "yup";

const SignIn = (): JSX.Element => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("validationSchema.invalid.email"))
      .required(t("validationSchema.required.email")),
    password: Yup.string().required(t("validationSchema.required.password")),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { email, password } = values;
        await signInUser(email, password);
      } catch (error: any) {
        setErrorAlert(error.message);
      }
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <CoverLayout image={bgImage}>
      {
        <MDSnackbar
          color="error"
          icon="warning"
          title="Authentification"
          content={errorAlert}
          open={!!errorAlert}
          onClose={() => setErrorAlert(null)}
          close={() => setErrorAlert(null)}
          bgWhite
        />
      }
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("auth.signIn.signIn")}
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} variant="body1" color="white">
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={signInWithGoogle}
                >
                  <GoogleIcon color="inherit" fontSize="medium" />
                </IconButton>
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label={t("form.email")}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={
                  formik.touched.email && (
                    <MDTypography variant="inherit" fontWeight="regular" color="error" mt={1}>
                      {formik.errors.email}
                    </MDTypography>
                  )
                }
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                name="password"
                label={t("form.password")}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={
                  formik.touched.password && (
                    <MDTypography variant="inherit" fontWeight="regular" color="error" mt={1}>
                      {formik.errors.password}
                    </MDTypography>
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;{t("auth.signIn.remember")}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {/* <Button type="submit" variant="contained" color="info" fullWidth>
                sign in
              </Button> */}
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                {t("auth.signIn.signIn")}
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" color="text">
                {`${t("auth.signIn.noAccount")} `}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("auth.signIn.signUp")}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {`${t("auth.signIn.forgotPassword")} `}
                <MDTypography
                  component={Link}
                  to="/authentication/reset-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {t("auth.signIn.reset")}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default SignIn;
