import * as Yup from "yup";
import checkout from "pages/design/schemas/general/form";
import i18next from "i18n";

const {
  formField: { name },
} = checkout;

const validations = Yup.object().shape({
  [name.name]: Yup.string().required(i18next.t(name.errorMsgKey)),
});

export default validations;
