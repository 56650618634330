import { CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import ProductCreateDescription from "pages/element/components/ProductCreateDescription";
import ProductCreateInfo from "pages/element/components/ProductCreateInfo";
import ProductCreateMedia from "pages/element/components/ProductCreateMedia";
import form from "pages/element/schemas/product/form";
import initialValues from "pages/element/schemas/product/initialValues";
import { validationsCreate } from "pages/element/schemas/product/validations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateProduct, useGetAllAttribute, useGetAllSubCategory } from "../element.hook";
import { IProductCreate, ISubCategory } from "../element.type";

const getSteps = (): string[] => {
  return ["1. Product Info", "3. Description", "2. Media"];
};

const AddProduct = (): JSX.Element => {
  const steps = getSteps();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formId, formField } = form;
  const createProduct = useCreateProduct();
  const [imageCroped, setImageCroped] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isLastStep = activeStep === steps.length - 1;
  const currentValidation = validationsCreate[activeStep];
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const { data: subCategoriesData, isLoading: isLoadingSubCategories } = useGetAllSubCategory();
  const { data: attributesData, isLoading: isLoadingAttribute } = useGetAllAttribute();

  const handleBack = () => setActiveStep(activeStep - 1);

  const getStepContent = (
    stepIndex: number,
    formData: any,
    subCategoriesData: ISubCategory[]
  ): JSX.Element => {
    switch (stepIndex) {
      case 0:
        return <ProductCreateInfo formData={formData} subCategoriesData={subCategoriesData} />;
      case 1:
        return <ProductCreateDescription formData={formData} allAttributes={attributesData} />;
      case 2:
        return <ProductCreateMedia formData={formData} setImageCroped={setImageCroped} />;
      default:
        return null;
    }
  };

  const submitForm = async (values: IProductCreate, actions: any) => {
    try {
      actions.setSubmitting(true);
      setIsLoading(true);
      if (imageCroped) values.file = imageCroped;

      const newProduct = await createProduct.mutateAsync({ data: values });
      setIsLoading(false);
      navigate(`/element/edit?id=${newProduct._id}`);
    } catch (error: any) {
      actions.setSubmitting(false);
      setIsLoading(false);
      setErrorAlertTitle(t("alert.create.titleError"));
      setErrorAlertContent(t("alert.create.contentError", { errorMsg: error.message }));
      setActiveStep(0);
    }
  };

  const handleSubmit = (values: any, actions: any) => {
    if (isLastStep) submitForm(values, actions);
    else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const renderAlertErrorCreate = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={5} mb={9}>
        {isLoadingSubCategories || isLoadingAttribute || isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <MDBox mt={6} mb={8} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {t("element.titleAddElement")}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id={formId} autoComplete="off">
                    <Card>
                      <MDBox mt={-3} mb={3} mx={2}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={2}>
                        <MDBox>
                          {getStepContent(
                            activeStep,
                            {
                              values,
                              touched,
                              formField,
                              errors,
                              setFieldValue,
                            },
                            subCategoriesData
                          )}
                          <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                            {activeStep === 0 ? (
                              <MDBox />
                            ) : (
                              <MDButton variant="gradient" color="light" onClick={handleBack}>
                                {t("form.back")}
                              </MDButton>
                            )}
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? t("form.validate") : t("form.next")}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        )}
      </MDBox>
      {renderAlertErrorCreate}
      <Footer />
    </DashboardLayout>
  );
};

export default AddProduct;
