import * as Yup from "yup";
import checkout from "pages/technicalService/schemas/general/form";
import i18next from "i18n";

const {
  formField: { category, description },
} = checkout;

const validations = Yup.object().shape({
  [category.name]: Yup.string().required(i18next.t(category.errorMsgKey)),
  [description.name]: Yup.string().required(i18next.t(description.errorMsgKey)),
});

export default validations;
