const form = {
  formId: "design-card-form",
  formField: {
    type: {
      name: "type",
      type: "text",
      errorMsgKey: "validationSchema.required.type",
    },
    bgColor: {
      name: "bgColor",
      type: "color",
      errorMsgKey: "validationSchema.required.bgColor",
    },
    textColor: {
      name: "textColor",
      type: "color",
      errorMsgKey: "validationSchema.required.textColor",
    },
    linkColor: {
      name: "linkColor",
      type: "color",
      errorMsgKey: "validationSchema.required.linkColor",
    },
  },
};

export default form;
