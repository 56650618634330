import { Card, Divider, Grid } from "@mui/material";
import CardProduct from "components/CardProduct/CardProduct";
import InfoBox from "components/InfoBox/InfoBox";
import MDBox from "components/MDBox";
import { useGetClientById } from "pages/client/client.hook";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ECurrency, SELECT_INFORMATION } from "types/client.type";
import { IReservation } from "../../reservation/reservation.type";
import CardReservation from "components/CardReservation/CardReservation";
import moment from "moment";
import "moment/locale/fr";
interface Props {
  currentRent?: IReservation;
}

const ModalInfoRent = forwardRef(({ currentRent }: Props) => {
  const { t } = useTranslation();
  moment.locale(t("lang"));
  const { data } = useGetClientById(SELECT_INFORMATION);
  const start = moment(currentRent.start);
  const end = moment(currentRent.end);

  return (
    <MDBox
      mt={1}
      mb={1}
      ml={0.5}
      sm={6}
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        width: "50vw",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
        }}
      >
        <MDBox>
          <Grid container spacing={3}>
            <Grid key={currentRent.productId.name} item xs={12} sm={12}>
              <CardReservation pictureUrl={""} name={currentRent.productId.name} />
              {start.date() == end.date() && start.month() == end.month()
                ? start.format("dddd DD MMMM") + " - " + start.format("HH:mm")
                : start.format("LLLL")}
              {` ${t("general.to")} `}
              {start.date() == end.date() && start.month() == end.month()
                ? end.format("HH:mm")
                : end.format("LLLL")}
            </Grid>
          </Grid>
        </MDBox>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <InfoBox title={t("form.client")} value={currentRent.name} />
            <a style={{ color: "#3b7776" }} href={`mailto:${currentRent.email}`}>
              {currentRent.email}
            </a>
            <br />
            <a style={{ color: "#3b7776" }} href={`tel:${currentRent.tel}`}>
              {currentRent.tel}
            </a>
            <br />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoBox
              title={t("form.status")}
              value={t("appointment.status." + currentRent.status)}
            />
            <InfoBox title={t("form.message")} value={currentRent.message} />
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
});

export default ModalInfoRent;
