export enum IStatusOrder {
  PENDING = "pending",
  INPROGRESS = "inProgress",
  DONE = "done",
}
export interface IOrder {
  _id: string;
  name: string;
  email: string;
  message: string;
  price: number;
  basket: IBasket[];
  status: IStatusOrder;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export interface IBasket {
  productId: string;
  quantity: number;
  price: string;
  name: string;
  url: string;
}

export interface IEvent {
  id: string;
  groupId: string;
  allDay: boolean;
  start: string;
  end: string;
  daysOfWeek: string;
  startTimeendTimestartRecur: string;
  title: string;
  endRecur: string;
  url: string;
  interactive: boolean;
  className: string;
  editable: boolean;
  startEditable: string;
  durationEditable: string;
  resourceEditableresourceId: string;
  resourceIds: string;
  display: string;
  overlap: string;
  constraint: string;
  color: string;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  duration: string;
  startTime: Date;
  endTime: Date;
  startRecur: Date;
  extendedProps: any;
}

export interface IOrderUpdate {
  id: string;
  name?: string;
  title?: string;
  price?: number;
  image?: string;
  subCategoryId?: string;
  description?: string;
  file?: Dropzone.DropzoneFile;
  attributes?: IAttributeOrder[];
  inCarousel?: boolean;
}

export interface IOrderCreate {
  name: string;
  title: string;
  price: number;
  image: string;
  subCategoryId: string;
  description: string;
  file: Dropzone.DropzoneFile;
  attributes?: IAttributeOrder[];
}

export interface ISubCategory {
  readonly _id?: string;
  categoryId: string;
  name: string;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export interface IAttribute {
  readonly _id?: string;
  name: string;
  value: string;
  type?: string;
  mediaId?: {
    url: string;
    _id: string;
  };
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export interface IAttributeOrder {
  orderAttributesId: string;
  order: number;
  details?: IAttribute;
}
export interface IAttributeCreate {
  title: string;
  subTitle: string;
  file?: Dropzone.DropzoneFile;
}

export enum ETypeAction {
  CREATE = "create",
  UPDATE = "update",
}
