export const generateNodesAndEdges = (arr: any[]) => {
  const nodes: any[] = [{ id: "n", label: "", data: { type: "company", id: "#", subCat: "" } }];
  const edges: any[] = [];

  for (let i = 0; i < arr.length; i++) {
    const obj = { id: "", label: "", data: { type: "", id: "", subCat: "" } };
    const type = arr[i]?.subCategoryId ? "product" : "category";
    obj.id = `n-${i}`;
    obj.label = arr[i]?.name;
    obj.data["type"] = type;
    obj.data["subCat"] = arr[i]?.subCategoryId || "";
    obj.data["id"] = `${arr[i]._id}`;
    nodes.push(obj);
  }
  nodes
    .filter((elem) => elem.data.type === "category")
    .forEach((node) => {
      const obj = { id: "", label: "", source: "", target: "" };
      obj.id = `${node.id}->${node.id}`;
      obj.source = "n";
      obj.target = node.id;
      obj.label = "";
      edges.push(obj);
    });

  nodes
    .filter((elem) => elem.data.type === "product")
    .forEach((node) => {
      const obj = { id: "", label: "", source: "", target: "" };
      obj.id = `${node.id}->${node.id}`;
      obj.source = nodes.find((elem) => elem.data.id === node.data.subCat)?.id;
      obj.target = node.id;
      obj.label = "";
      edges.push(obj);
    });
  return { nodes, edges };
};
