const form = {
  formId: "design-home-page-form",
  formField: {
    type: {
      name: "type",
      type: "text",
      errorMsgKey: "validationSchema.required.type",
    },
    sections: {
      name: "sections",
      type: "array",
      errorMsgKey: "validationSchema.required.sections",
    },
  },
};

export default form;
