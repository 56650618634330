import { Box, CircularProgress, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { EDesignTypeCard } from "../../pages/design/design.type";
import { useGetClientById } from "pages/client/client.hook";
import { SELECT_DESIGN } from "types/client.type";
import MDBox from "components/MDBox";
import { memo } from "react";
import defaultImage from "assets/images/bg-sign-up-cover.jpeg";

interface Props {
  backgroundColor?: string;
  textColor?: string;
  linkColor?: string;
  typeCard?: EDesignTypeCard;
  isLandscape: boolean;
  data: {
    title: string;
    image: string;
  };
}

const MockupCard = memo(
  ({ backgroundColor, textColor, linkColor, typeCard, isLandscape = false, data }: Props) => {
    const { data: currentDesign, isLoading: isLoadingCurrentDesign } =
      useGetClientById(SELECT_DESIGN);
    const designCard = currentDesign?.design?.card;
    const styleNav = {
      typeCard: typeCard || designCard?.type,
      backgroundColor: backgroundColor || designCard?.bgColor,
      textColor: textColor || designCard?.textColor,
      linkColor: linkColor || designCard?.linkColor,
    };
    const sizeBorder = styleNav.typeCard === EDesignTypeCard.NOBORDER ? 0 : 7;
    const maxLength = 30;
    const truncatedTitle =
      data?.title?.length > maxLength ? `${data.title.substring(0, maxLength)}...` : data?.title;

    return (
      <>
        {isLoadingCurrentDesign ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="40vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {!isLandscape ? (
              <Card
                sx={{
                  m: 1,
                  mt: -0.5,
                  height: 150,
                  border: sizeBorder,
                  borderColor: styleNav.backgroundColor,
                  bgcolor: styleNav.backgroundColor,
                }}
              >
                <CardMedia
                  sx={{ m: 0, height: 75, borderRadius: 2 }}
                  component="img"
                  image={data.image}
                  alt="green iguana"
                />

                <CardContent
                  sx={{ p: 0, pl: styleNav.typeCard === EDesignTypeCard.NOBORDER ? 1 : 0, pt: 1 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} height={26}>
                      <Box>
                        <Typography
                          variant="body2"
                          color={styleNav.textColor}
                          sx={{ lineHeight: "1", fontSize: "0.8rem" }}
                        >
                          {truncatedTitle}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ marginTop: "0px" }}>
                        <Typography variant="caption" color={styleNav.linkColor}>
                          {"Lire l'article >"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ) : (
              <Card
                sx={{
                  m: 1,
                  mt: -0.5,
                  border: sizeBorder,
                  borderColor: styleNav.backgroundColor,
                  bgcolor: styleNav.backgroundColor,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6} sm container>
                    <CardMedia
                      sx={{ m: 0, height: 82, width: 115, borderRadius: 2 }}
                      component="img"
                      image={data.image || defaultImage}
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid item xs={6} sm container>
                    <Grid container spacing={7}>
                      <Grid item xs={12} height={26}>
                        <Box mt={1}>
                          <Typography
                            variant="body2"
                            color={styleNav.textColor}
                            sx={{ lineHeight: "1", fontSize: "0.8rem" }}
                          >
                            {truncatedTitle}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ marginTop: "-10px" }}>
                          <Typography variant="caption" color={styleNav.linkColor}>
                            {"Lire l'article >"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            )}
          </>
        )}
      </>
    );
  }
);

export default MockupCard;
