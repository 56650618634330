import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Navigation from "components/NavigationPage/Navigation";
import ListeOrder from "pages/order/pages/ListeOrder";
import CalendarPage from "pages/order/pages/CalendarPage";
import { useTranslation } from "react-i18next";
import OrderKanban from "layouts/order-kanban";
import Calendar from "layouts/Calendar";

const Index = () => {
  const { t } = useTranslation();

  const tabs = [
    { label: t("order.tabs.list"), content: <ListeOrder />, mode: "inside" },
    {
      label: t("order.tabs.board"),
      content: <OrderKanban />,
      mode: "inside",
    },
  ];

  return <Navigation stickyNavbar={true} tabs={tabs}></Navigation>;
};

export default Index;
