import api from "config/axios.config";
import { IAttribute, IProduct, ISubCategory } from "./element.type";

interface PropsProduct {
  clientId: string;
  productId: string;
  data?: FormData;
}
interface PropsSubCategory {
  clientId: string;
  subCategoryId?: string;
  data?: { name: string };
}

interface PropsAttribute {
  clientId: string;
  attributeId?: string;
  data?: FormData;
}

//**! mettre a jour la requet dans le mobile pour utiliser celle la */
export const getAllProducts = (clientId: string): Promise<IProduct[]> => {
  return api.get(
    `/clients/${clientId}/product/dashboard?include=subCategoryId&select=name,price,subCategoryId,updatedAt`
  );
};

export const createProduct = ({ clientId, data }: PropsAttribute): Promise<IProduct> => {
  return api.post(`clients/${clientId}/product`, data);
};

export const deleteProduct = ({ clientId, productId }: PropsProduct): Promise<IProduct> => {
  return api.delete(`/clients/${clientId}/product/${productId}`);
};

export const synchShopifyProducts = ({ clientId }: any): Promise<IProduct> => {
  return api.get(`/clients/${clientId}/shopify/synchronize/product`);
};

export const getProductById = ({ clientId, productId }: PropsProduct): Promise<IProduct> => {
  return api.get(`/clients/${clientId}/product/${productId}/dashboard`);
};

export const getProductTop6 = (clientId: string): Promise<IProduct> => {
  return api.get(`/clients/${clientId}/product/top6`);
};

export const updateProduct = ({ clientId, productId, data }: PropsProduct): Promise<IProduct> => {
  return api.patch(`clients/${clientId}/product/${productId}`, data);
};

export const deleteCategory = ({
  clientId,
  subCategoryId,
}: PropsSubCategory): Promise<ISubCategory> => {
  return api.delete(`/clients/${clientId}/sub-category/${subCategoryId}`);
};

export const getAllSubCategory = (clientId: string): Promise<ISubCategory[]> => {
  return api.get(`clients/${clientId}/sub-category`);
};

export const createSubCategory = ({ clientId, data }: PropsSubCategory): Promise<ISubCategory> => {
  return api.post(`clients/${clientId}/sub-category`, data);
};

export const updateSubCategory = ({
  clientId,
  subCategoryId,
  data,
}: PropsSubCategory): Promise<ISubCategory> => {
  return api.patch(`clients/${clientId}/sub-category/${subCategoryId}`, data);
};

export const getAllAttributes = (clientId: string): Promise<IAttribute[]> => {
  return api.get(`/clients/${clientId}/product-attribute`);
};

export const createAttribute = ({ clientId, data }: PropsAttribute): Promise<IAttribute> => {
  return api.post(`clients/${clientId}/product-attribute`, data);
};

export const deleteAttribute = ({ clientId, attributeId }: PropsAttribute): Promise<IAttribute> => {
  return api.delete(`/clients/${clientId}/product-attribute/${attributeId}`);
};

export const updateAttribute = ({
  clientId,
  attributeId,
  data,
}: PropsAttribute): Promise<IAttribute> => {
  return api.patch(`/clients/${clientId}/product-attribute/${attributeId}`, data);
};
