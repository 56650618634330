import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import qrCodeAndroid from "assets/images/qrCode-hopelappTool-android.png";
import qrCodeIos from "assets/images/qrCode-hopelappTool-ios.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import { config } from "config/config";

const QrCodeApp = () => {
  const { t } = useTranslation();

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Link href={config.hopelapp.hopelappToolLinkIos} target="_blank">
              <MDBox
                component="img"
                src={qrCodeIos}
                alt="logo"
                width={80}
                height="auto"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </Grid>
          <Grid item>
            <MDBox height="100%">
              <Link href={config.hopelapp.hopelappToolLinkIos} target="_blank">
                <MDTypography variant="h6" fontWeight="medium">
                  Hopelapp Tool IOS
                </MDTypography>
                <MDTypography variant="caption" fontWeight="regular">
                  version : 1
                </MDTypography>
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }} margin={2}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular" margin={2}>
                {t("common.status")} :
              </MDTypography>
              <MDBox
                component="span"
                bgColor="success"
                sx={{ width: 30, height: 30, borderRadius: "50%" }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Link href={config.hopelapp.hopelappToolLinkAndroid} target="_blank">
              <MDBox component="img" src={qrCodeAndroid} alt="logo" width={80} height="auto" />
            </Link>
          </Grid>
          <Grid item>
            <MDBox height="100%">
              <Link href={config.hopelapp.hopelappToolLinkAndroid} target="_blank">
                <MDTypography variant="h6" fontWeight="medium">
                  Hopelapp Tool Android
                </MDTypography>
                <MDTypography variant="caption" fontWeight="regular">
                  version : 1
                </MDTypography>
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }} margin={2}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular" margin={2}>
                {t("common.status")} :
              </MDTypography>
              <MDBox
                component="span"
                bgColor="success"
                sx={{ width: 30, height: 30, borderRadius: "50%" }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default QrCodeApp;
