import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import { ISection } from "pages/design/design.type";
import { useGetProductTop6 } from "pages/element/element.hook";
import { memo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import defaultImage from "assets/images/bg-sign-up-cover.jpeg";

interface Props {
  section?: ISection;
}

const MockupSectionCarousel = memo(({ section }: Props): JSX.Element => {
  const { data, isLoading } = useGetProductTop6();
  const custome = {
    bgColor: section?.bgColor || "#ffffff",
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: false,
  };

  return (
    <>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="40vh">
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <MDBox p={2} bgColor={custome.bgColor}>
          <Slider {...settings}>
            {data.slice(-3)?.map((element, index) => (
              <div key={element._id}>
                <MDBox width={150} mr={5}>
                  <MDBox
                    component="img"
                    src={element.mediaId?.url || defaultImage}
                    alt="image element"
                    borderRadius="10px"
                    width="180px"
                    height="100px"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MDBox>
              </div>
            ))}
          </Slider>
        </MDBox>
      )}
    </>
  );
});

export default MockupSectionCarousel;
