import MDEditorRoot from "components/MDEditor/MDEditorRoot";
import { useMaterialUIController } from "context/materialUI-context/materialUI.context";
import { convertToHTML } from "draft-convert";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import cleanHtml from "utils/cleanHtml.util";

interface MDEditorProps {
  value?: (content: string) => void;
  maxLength?: number;
  defaultValue?: string;
}

const toolbarOptions = {
  options: ["inline", "blockType", "list", "emoji"],
  inline: {
    options: ["bold", "italic", "monospace"],
  },
  blockType: {
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
};

const MDEditor: React.FC<MDEditorProps> = ({ value, maxLength, defaultValue }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [editorState, setEditorState] = useState(() => {
    if (defaultValue) {
      const sanitizedHtml = cleanHtml(defaultValue);
      const blocksFromHTML = convertFromHTML(sanitizedHtml);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(state);
    } else {
      return EditorState.createEmpty();
    }
  });

  const [showAlert, setShowAlert] = useState(false);

  const handleEditorChange = (newEditorState: EditorState) => {
    const content = newEditorState.getCurrentContent().getPlainText();

    if (maxLength && content.length > maxLength) {
      setShowAlert(true);
      return;
    }

    setShowAlert(false);
    setEditorState(newEditorState);
    const html = convertToHTML(newEditorState.getCurrentContent());
    const cleanData = cleanHtml(html);
    value(cleanData);
  };

  const characterCount = editorState.getCurrentContent().getPlainText().length;

  useEffect(() => {
    if (defaultValue) {
      const sanitizedHtml = cleanHtml(defaultValue);
      const blocksFromHTML = convertFromHTML(sanitizedHtml);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [defaultValue]);

  return (
    <MDEditorRoot ownerState={{ darkMode }}>
      <Editor
        toolbar={toolbarOptions}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
      />
      <div style={{ marginTop: 10, textAlign: "right", color: "#888", fontSize: "14px" }}>
        {showAlert && <div style={{ color: "red" }}>Limite de caractères atteinte!</div>}
        <span>
          {characterCount} / {maxLength}
        </span>
      </div>
    </MDEditorRoot>
  );
};

MDEditor.defaultProps = {
  value: () => {},
  maxLength: null,
  defaultValue: "",
};

MDEditor.propTypes = {
  value: PropTypes.func,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
};

export default MDEditor;
