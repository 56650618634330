import checkout from "pages/technicalService/schemas/general/form";

const {
  formField: { category, description, image },
} = checkout;

const initialValues = {
  [category.name]: "",
  [description.name]: "",
  [image.name]: "",
};

export default initialValues;
