import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@mui/material";
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InputBase from "@mui/material/InputBase";
import Popper from "@mui/material/Popper";
import { styled, useTheme } from "@mui/material/styles";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal from "components/Modal/modal";
import ModalCreateAttribute from "pages/element/components/ModalCreateOrUpdateAttribute";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAttribute, IAttributeOrder } from "../element.type";

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

interface Props {
  currentAttributes: IAttributeOrder[];
  allAttributes: IAttribute[];
  onChange: (content: IAttributeOrder[]) => void;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === "light" ? " #eaecef" : "#30363d"}`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

const PopperComponent = (props: PopperComponentProps) => {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `1px solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light" ? "rgba(3, 102, 214, 0.3)" : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  width: "100%",
  textAlign: "left",
  paddingBottom: 8,
  color: theme.palette.mode === "light" ? "#586069" : "#8b949e",
  fontWeight: 600,
  "&:hover,&:focus": {
    color: theme.palette.mode === "light" ? "#0366d6" : "#58a6ff",
  },
  "& span": {
    width: "100%",
  },
  "& svg": {
    width: 16,
    height: 16,
  },
}));

const AddAttribute = ({ currentAttributes, allAttributes, onChange }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const productAttributesId = currentAttributes
    ? currentAttributes?.map((attribute) => attribute.productAttributesId)
    : [];
  const [value, setValue] = useState(
    allAttributes
      .filter((row) => productAttributesId.includes(row._id))
      .sort((a, b) => {
        const orderA = currentAttributes.find((item) => item.productAttributesId === a._id);
        const orderB = currentAttributes.find((item) => item.productAttributesId === b._id);
        return orderA.order - orderB.order;
      })
  );
  const [pendingValue, setPendingValue] = useState<IAttribute[]>([]);
  const modalCreateAttributeRef = useRef(null);
  const theme = useTheme();
  const [openAlertCreateAttribute, setOpenAlertCreateAttribute] = useState(false);
  const [errorAlertTitleAttribute, setErrorAlertTitleAttribute] = useState<string | null>(null);
  const [errorAlertContentAttribute, setErrorAlertContentAttribute] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue(pendingValue);
    const resultAttributes = pendingValue.map(({ _id }, index) => ({
      productAttributesId: _id,
      order: index + 1,
    }));
    onChange(resultAttributes);
    if (anchorEl) anchorEl.focus();
    setAnchorEl(null);
  };

  useEffect(() => {
    const productAttributesId = currentAttributes
      ? currentAttributes?.map((attribute) => attribute.productAttributesId)
      : [];
    setValue(
      allAttributes
        .filter((row) => productAttributesId.includes(row._id))
        .sort((a, b) => {
          const orderA = currentAttributes.find((item) => item.productAttributesId === a._id);
          const orderB = currentAttributes.find((item) => item.productAttributesId === b._id);
          return orderA.order - orderB.order;
        })
    );
  }, [currentAttributes]);

  const renderAttribute = ({ _id, mediaId, name, value }: IAttribute) => (
    <MDBox key={_id} component="li" display="flex" alignItems="center" py={1}>
      {mediaId?.url && (
        <MDBox mr={2}>
          <MDBox
            component="img"
            src={mediaId.url}
            alt="image product"
            borderRadius="10px"
            width="40px"
            height="40px"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </MDBox>
      )}
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {value}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const handleValidSubmit = async () => {
    if (modalCreateAttributeRef.current) {
      return await modalCreateAttributeRef.current.submitForm();
    }
  };

  const renderCreateAttribute = (
    <Modal
      title={t("attribute.modal.create.title")}
      openModal={openModal}
      body={
        <ModalCreateAttribute
          onFormSubmit={() => {
            setOpenModal(false);
            setOpenAlertCreateAttribute(true);
          }}
          onError={(error) => {
            setErrorAlertTitleAttribute(t("alert.create.titleError"));
            setErrorAlertContentAttribute(
              t("alert.create.contentError", { errorMsg: t("alert.errorMsg.refresh") })
            );
          }}
          ref={modalCreateAttributeRef}
        />
      }
      handleValid={handleValidSubmit}
      handleClose={() => setOpenModal(false)}
    />
  );

  const renderAlertCreateAttribute = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.create.title")}
      content={t("alert.create.content")}
      open={openAlertCreateAttribute}
      onClose={() => setOpenAlertCreateAttribute(false)}
      close={() => setOpenAlertCreateAttribute(false)}
      bgWhite
    />
  );

  const renderAlertErrorAttribute = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitleAttribute}
      content={errorAlertContentAttribute}
      open={!!errorAlertContentAttribute}
      onClose={() => setErrorAlertContentAttribute(null)}
      close={() => setErrorAlertContentAttribute(null)}
      bgWhite
    />
  );

  const open = Boolean(anchorEl);
  const id = open ? "github-label" : undefined;

  return (
    <React.Fragment>
      <MDBox
        sx={{
          pt: 1,
          borderRadius: 2,
          width: 221,
          fontSize: 13,
          flexDirection: "column",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#eee",
          },
        }}
      >
        <Button
          disableRipple
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <MDTypography
              textAlign="left"
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              {t("form.attribute")}
            </MDTypography>
          </div>
          <SettingsIcon color="success" />
        </Button>
      </MDBox>
      <MDBox sx={{ width: 320, fontSize: 13 }}>
        {value.map((attribute) => renderAttribute(attribute))}
      </MDBox>

      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              onClick={() => setOpenModal(true)}
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
                "&:hover": {
                  backgroundColor: "#eee",
                },
              }}
            >
              <MDButton size="small" variant="text" color="success" style={{ width: "100%" }}>
                <Icon>add</Icon>&nbsp; {t("attribute.addAttribute")}
              </MDButton>
            </Box>
            <Autocomplete
              open
              multiple
              onClose={(event: React.ChangeEvent<object>, reason: AutocompleteCloseReason) => {
                if (reason === "escape") handleClose();
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  (event as React.KeyboardEvent).key === "Backspace" &&
                  reason === "removeOption"
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No labels"
              renderOption={(props, option, { selected }) => (
                <li key={option._id} {...props}>
                  <MDBox display="flex" alignItems="center">
                    <MDBox
                      component={DoneIcon}
                      sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
                      style={{
                        visibility: selected ? "visible" : "hidden",
                      }}
                    />
                    <MDBox mr={2}>
                      <MDAvatar
                        src={option.mediaId?.url}
                        alt="something here"
                        shadow="md"
                        size="sm"
                      />
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <MDTypography variant="button" fontWeight="medium" color="inherit">
                        {option.name}
                      </MDTypography>
                      <MDTypography variant="caption" color="disabled">
                        {option.value}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      component={CloseIcon}
                      sx={{ opacity: 0.6, width: 18, height: 18, ml: 2 }}
                      style={{
                        visibility: selected ? "visible" : "hidden",
                      }}
                    />
                  </MDBox>
                </li>
              )}
              options={[...allAttributes].sort((a, b) => {
                // Display the selected labels first.
                let ai = value.indexOf(a);
                ai = ai === -1 ? value.length + allAttributes.indexOf(a) : ai;
                let bi = value.indexOf(b);
                bi = bi === -1 ? value.length + allAttributes.indexOf(b) : bi;
                return ai - bi;
              })}
              getOptionLabel={(option) => `${option.name}-${option.value}`}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Filter labels"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
      {renderCreateAttribute}
      {renderAlertCreateAttribute}
      {renderAlertErrorAttribute}
    </React.Fragment>
  );
};

export default AddAttribute;
