import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import {
  getClients,
  getClientsById,
  getTemplates,
  updateClient,
  updateFeature,
} from "services/client.service";
import { DataFeatures, IClient, SELECT_DESIGN, SELECT_FEATURES } from "types/client.type";
import { EFeatures } from "types/features.type";
import RolesType from "types/roles.type";

export interface IUseUpdateClient {
  select: string;
}
export interface IUseUpdateFeatureClient {
  feature: EFeatures;
  dataFeature: DataFeatures;
  select?: string;
}

export enum KeyQueryClient {
  getById = "getClientByIds",
  getClients = "getClients",
  patchClient = "patchClient",
  post = "postClient",
  getClientsTemplates = "getClientsTemplates",
  getClientsFeatures = "getClientsFeatures",
}

export const useGetClientById = (select: string): UseQueryResult<IClient> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryClient.getById, clientId, select], async () => {
    if (!clientId) return null;
    // await getClientsById(clientId, select);
    return getClientsById(clientId, select);
  });
};

export const useGetClients = (): UseQueryResult<IClient[]> => {
  const { currentAdmin } = useContext(AuthContext);
  const isSuperAdmin = currentAdmin?.role?.includes(RolesType.SUPER_ADMIN);

  return useQuery([KeyQueryClient.getClients], () => {
    return isSuperAdmin && getClients();
  });
};

export const useGetTemplate = () => {
  return useQuery([KeyQueryClient.getClientsTemplates], () => {
    return getTemplates();
  });
};

export const useUpdateClient = ({ select }: IUseUpdateClient) => {
  const queryClient = useQueryClient();
  const { clientId } = useContext(AuthContext);

  return useMutation({
    mutationFn: (data: IClient) => {
      const formData = new FormData();
      if (data.logo) formData.append("file", data.logo);
      if (data.longDesc) formData.append("longDesc", data.longDesc);
      if (data.name) formData.append("name", data.name);
      if (data.shortDesc) formData.append("shortDesc", data.shortDesc);
      return updateClient(clientId, formData, select);
    },
    onSuccess: (result) => {
      queryClient.setQueryData([KeyQueryClient.getById, clientId, select], result);
      const currentDesign: IClient = queryClient.getQueryData([
        KeyQueryClient.getById,
        clientId,
        SELECT_DESIGN,
      ]);
      if (currentDesign) {
        queryClient.setQueryData([KeyQueryClient.getById, clientId, SELECT_DESIGN], {
          ...currentDesign,
          name: result.name,
          logo: result.logo,
        });
      }
    },
  });
};

export const useUpdateFeatureClient = () => {
  const queryClient = useQueryClient();
  const { clientId } = useContext(AuthContext);

  return useMutation({
    mutationFn: async ({ feature, dataFeature, select }: IUseUpdateFeatureClient) => {
      return updateFeature(clientId, feature, dataFeature, select);
    },
    onSuccess: (result, variables) => {
      queryClient.setQueryData(
        [KeyQueryClient.getClientsFeatures, clientId, SELECT_FEATURES],
        result
      );
      const currentClient: IClient = queryClient.getQueryData([
        KeyQueryClient.getById,
        clientId,
        SELECT_DESIGN,
      ]);
      if (currentClient) {
        queryClient.setQueryData([KeyQueryClient.getClientsFeatures, clientId, SELECT_FEATURES], {
          ...currentClient,
          features: result.features,
        });
      }
    },
  });
};
