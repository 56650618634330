import { Grid, Icon } from "@mui/material";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Modal, { EType } from "components/Modal/modal";
import DataTable from "examples/Tables/DataTable";
import _ from "lodash";
import ModalCreateOrUpdateAttribute from "pages/element/components/ModalCreateOrUpdateAttribute";
import ModalDelete from "pages/element/components/ModalDelete";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteAttribute, useGetAllAttribute } from "../element.hook";
import { ETypeAction, IAttribute } from "../element.type";
import moment from "moment";

const ListeAttribute = (): JSX.Element => {
  const { t } = useTranslation();
  moment.locale(t("lang"));
  const deleteAttribute = useDeleteAttribute();
  const modalCreateAttributeRef = useRef(null);
  const { data, isLoading } = useGetAllAttribute();
  const [showError, setShowError] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [typeAction, setTypeAction] = useState<ETypeAction>(ETypeAction.CREATE);
  const [openAlertCreateAttribute, setOpenAlertCreateAttribute] = useState(false);
  const [selectAttribute, setSelectAttribute] = useState<IAttribute | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const [errorAlertTitleAttribute, setErrorAlertTitleAttribute] = useState<string | null>(null);
  const [errorAlertContentAttribute, setErrorAlertContentAttribute] = useState<string | null>(null);

  const dataTableAttribute = {
    columns: [
      {
        Header: t("attribute.headerImage"),
        accessor: "mediaId.url",
        width: "15%",
        Cell: ({ row }: any) => {
          const url = _.get(row, "original.mediaId.url");
          return (
            <>
              {url && (
                <MDBox
                  component="img"
                  src={url}
                  alt="image product"
                  borderRadius="10px"
                  width="40px"
                  height="40px"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              )}
            </>
          );
        },
      },
      //**! trouver une solution d'affichage lorsque le text est trop grand */
      { Header: t("attribute.headerTitle"), accessor: "name", width: "30%" },
      { Header: t("attribute.headerSubTitle"), accessor: "value", width: "30%" },
      {
        Header: t("attribute.headerUpdatedAt"),
        accessor: (row: any) => moment(row?.updatedAt).format("MM/DD/YYYY HH:mm"),
        width: "15%",
      },
      {
        Header: t("attribute.action"),
        accessor: "action",
        Cell: ({ row }: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <MDBox>
                <MDButton
                  variant="text"
                  color={"dark"}
                  onClick={() => handleActionUpdate(row.original)}
                >
                  <Icon>edit</Icon>
                </MDButton>
              </MDBox>
              <MDButton
                variant="text"
                color="error"
                onClick={() => handleActionDelete(row.original)}
              >
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          );
        },
      },
    ],
    rows: data,
  };

  const handleActionDelete = (attribute: IAttribute) => {
    setSelectAttribute(attribute);
    setOpenModalDelete(true);
  };

  const handleActionUpdate = (attribute: IAttribute) => {
    setSelectAttribute(attribute);
    setTypeAction(ETypeAction.UPDATE);
    setOpenModalCreate(true);
  };

  const handleCreate = () => {
    setSelectAttribute(null);
    setTypeAction(ETypeAction.CREATE);
    setOpenModalCreate(true);
  };

  const handleDelete = async () => {
    try {
      await deleteAttribute.mutateAsync(selectAttribute?._id);
      setOpenModalDelete(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: selectAttribute?.name }));
    }
  };

  const handleCloseDelete = () => setOpenModalDelete(false);

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={t("alert.delete.content", { item: selectAttribute?.name })}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const renderAlertCreateAttribute = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t(`alert.${typeAction}.title`)}
      content={t(`alert.${typeAction}.content`)}
      open={openAlertCreateAttribute}
      onClose={() => setOpenAlertCreateAttribute(false)}
      close={() => setOpenAlertCreateAttribute(false)}
      bgWhite
    />
  );

  const renderAlertErrorAttribute = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitleAttribute}
      content={errorAlertContentAttribute}
      open={!!errorAlertContentAttribute}
      onClose={() => setErrorAlertContentAttribute(null)}
      close={() => setErrorAlertContentAttribute(null)}
      bgWhite
    />
  );

  const renderModalDelete = (
    <Modal
      title={t("element.modalDelete.title")}
      openModal={openModalDelete}
      body={<ModalDelete name={selectAttribute?.name} />}
      handleValid={handleDelete}
      handleClose={handleCloseDelete}
      type={EType.delete}
    />
  );

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!data);
    }, 2000);
  };

  const handleValidSubmit = async () => {
    if (modalCreateAttributeRef.current) return await modalCreateAttributeRef.current.submitForm();
  };

  const renderCreateOrUpdateAttribute = (
    <Modal
      title={t(`attribute.modal.${typeAction}.title`)}
      openModal={openModalCreate}
      body={
        <ModalCreateOrUpdateAttribute
          onFormSubmit={() => {
            setOpenModalCreate(false);
            setOpenAlertCreateAttribute(true);
          }}
          onError={() => {
            setErrorAlertTitleAttribute(t(`alert.${typeAction}.titleError`));
            setErrorAlertContentAttribute(
              t(`alert.${typeAction}.contentError`, { errorMsg: t("alert.errorMsg.refresh") })
            );
          }}
          currentAttribute={selectAttribute}
          ref={modalCreateAttributeRef}
        />
      }
      handleValid={handleValidSubmit}
      handleClose={() => setOpenModalCreate(false)}
    />
  );

  return (
    <>
      <MDBox pt={1} pb={3}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {data ? (
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} md={7}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {t("attribute.titleTable")}
                      </MDTypography>
                      <MDTypography variant="button" color="text">
                        {t("attribute.descriptionTable")}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                      <MDButton variant="gradient" color="info" onClick={() => handleCreate()}>
                        <Icon>add</Icon>&nbsp; {t("attribute.addAttribute")}
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>

                <DataTable table={dataTableAttribute} canSearch />
              </Card>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("element.errorGetProduct")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </MDBox>

      {renderModalDelete}
      {renderAlertDelete}
      {renderAlertError}
      {renderCreateOrUpdateAttribute}
      {renderAlertErrorAttribute}
      {renderAlertCreateAttribute}
    </>
  );
};

export default React.memo(ListeAttribute);
