import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import CoverLayout from "layouts/authentication/components/Layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { createAdminEmailPassword } from "services/firebase.service";
import * as Yup from "yup";

const SignUp = (): JSX.Element => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("validationSchema.required.firstName")),
    lastName: Yup.string().required(t("validationSchema.required.name")),
    email: Yup.string()
      .email(t("validationSchema.invalid.email"))
      .required(t("validationSchema.required.email")),
    password: Yup.string()
      .required(t("validationSchema.required.password"))
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).*$/,
        t("validationSchema.invalid.password")
      )
      .min(8, t("validationSchema.invalid.passwordMin8")),
  });

  const formik = useFormik({
    initialValues: { firstName: "", lastName: "", email: "", password: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { firstName, lastName, email, password } = values;
        await createAdminEmailPassword({ firstName, lastName, email, password });
      } catch (error: any) {
        setErrorAlert(error.message);
      }
    },
  });

  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <CoverLayout image={bgImage}>
      {
        <MDSnackbar
          color="error"
          icon="warning"
          title="Authentification"
          content={errorAlert}
          open={!!errorAlert}
          onClose={() => setErrorAlert(null)}
          close={() => setErrorAlert(null)}
          bgWhite
        />
      }
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1 }}>
            <Grid item xs={2}>
              <MDTypography variant="body1" color="white">
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={signInWithGoogle}
                >
                  <GoogleIcon color="inherit" fontSize="medium" />
                </IconButton>
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <MDBox mb={2}>
              <MDInput
                type="firstName"
                name="firstName"
                label="Prénom"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={
                  formik.touched.firstName && (
                    <MDTypography variant="inherit" fontWeight="regular" color="error" mt={1}>
                      {formik.errors.firstName}
                    </MDTypography>
                  )
                }
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="lastName"
                name="lastName"
                label="Nom"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                helperText={
                  formik.touched.lastName && (
                    <MDTypography variant="inherit" fontWeight="regular" color="error" mt={1}>
                      {formik.errors.lastName}
                    </MDTypography>
                  )
                }
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label="Email"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={
                  formik.touched.email && (
                    <MDTypography variant="inherit" fontWeight="regular" color="error" mt={1}>
                      {formik.errors.email}
                    </MDTypography>
                  )
                }
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                name="password"
                label="Mot de passe"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={
                  formik.touched.password && (
                    <MDTypography variant="inherit" fontWeight="regular" color="error" mt={1}>
                      {formik.errors.password}
                    </MDTypography>
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default SignUp;
