const form = {
  formId: "design-navigation-form",
  formField: {
    type: {
      name: "type",
      type: "text",
      errorMsgKey: "validationSchema.required.type",
    },
    bgColor: {
      name: "bgColor",
      type: "color",
      errorMsgKey: "validationSchema.required.bgColor",
    },
    textColor: {
      name: "textColor",
      type: "color",
      errorMsgKey: "validationSchema.required.textColor",
    },
    activeBgColor: {
      name: "activeBgColor",
      type: "color",
      errorMsgKey: "validationSchema.required.activeBgColor",
    },
  },
};

export default form;
