const form = {
  formId: "design-general-form",
  formField: {
    category: {
      name: "category",
      type: "text",
      errorMsgKey: "validationSchema.required.category",
    },
    description: {
      name: "description",
      type: "text",
      errorMsgKey: "validationSchema.required.description",
    },
    image: {
      name: "image",
      type: "file",
    },
  },
};

export default form;
