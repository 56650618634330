import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";
import { CameraMode, GraphCanvas, GraphCanvasRef, useSelection } from "reagraph";
import { generateNodesAndEdges } from "utils/network-graph.util";
import { useGetAllProduct, useGetAllSubCategory } from "../element.hook";

const Cartography = (): JSX.Element => {
  //**! library a delete si on ne l'utilse pas
  //**! "reagraph": "^4.14.1",
  // Callback to handle click on the graph.

  const [mode, setMode] = useState<CameraMode>("orbit");
  const [selectedNode, setSelectedNode] = useState<string>("");
  const [complexNodes, setComplexNodes] = useState<any>([]);
  const [complexEdges, setComplexEdges] = useState<any>([]);
  const { data, isLoading } = useGetAllProduct();
  const getAllSubCatResultat = useGetAllSubCategory();

  const graphRef = useRef<GraphCanvasRef | null>(null);
  const { selections, onNodeClick, onCanvasClick } = useSelection({
    ref: graphRef,
    nodes: complexNodes,
    edges: complexEdges,
    focusOnSelect: false,
    pathSelectionType: "in",
  });

  useEffect(() => {
    if (data && getAllSubCatResultat.data) {
      const mergeArr = [...data, ...getAllSubCatResultat.data];
      const { nodes, edges } = generateNodesAndEdges(mergeArr);
      setComplexNodes(nodes);
      setComplexEdges(edges);
    }
    //data.map(elem => elem.subCategoryId)
  }, [getAllSubCatResultat.data, isLoading]);

  return (
    <MDBox my={3}>
      <MDBox mb={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MDBox mt={1} mb={2}>
              {!isLoading && complexNodes?.length > 0 && (
                <GraphCanvas
                  cameraMode={mode}
                  nodes={complexNodes}
                  edges={complexEdges}
                  draggable={true}
                  selections={selections}
                  onNodeClick={(e: any) => {
                    if (e.id !== selectedNode) {
                      setMode("pan");
                    } else {
                      setMode("orbit");
                    }
                    if (
                      e.id === selectedNode &&
                      e.data.type !== "company" &&
                      e.data.type !== "category"
                    ) {
                      //if (confirm(`Redirection vers ${e.data.type} : ${e.label}`))
                      window.location.href = `/element/edit?id=${e.data.id}`;
                    }
                    onNodeClick(e);
                    setSelectedNode(e.id);
                  }}
                  ref={graphRef}
                  /*renderNode={({ node, ...rest }) => {
                    return (
                      <Svg
                        {...rest}
                        node={node}
                        image={node.icon || "https://svgsilh.com/svg/2056977.svg"}
                      />
                    );
                  }}*/
                  onCanvasClick={(e: any) => {
                    setMode("orbit");
                    setSelectedNode("");
                    onCanvasClick(e);
                  }}
                />
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default Cartography;
