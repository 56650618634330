import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Navigation from "components/NavigationPage/Navigation";
import ListeAppointment from "pages/appointment/pages/ListeAppointment";
import CalendarPage from "pages/appointment/pages/CalendarPage";
import { useTranslation } from "react-i18next";
import OrderKanban from "layouts/order-kanban";
import Calendar from "layouts/Calendar";

const Index = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("appointment.tabs.calendar"),
      content: <CalendarPage />,
      mode: "inside",
    },
    {
      label: t("appointment.tabs.list"),
      content: <ListeAppointment />,
      mode: "inside",
    },
  ];

  return (
    <Navigation stickyNavbar={true} tabs={tabs}>
      <></>
    </Navigation>
  );
};

export default Index;
