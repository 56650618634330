import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MockupPhone from "components/MockupPhone/MockPhone";
import CardMockup from "components/MockupPhone/MockupCard";
import { memo } from "react";
import { useGetProductTop6 } from "pages/element/element.hook";
import defaultImage from "assets/images/bg-sign-up-cover.jpeg";
interface Props {
  formData: any;
}

const MockupPhoneCard = memo(({ formData }: Props): JSX.Element => {
  const { values } = formData;
  const { data, isLoading } = useGetProductTop6();

  const { type: typeV, bgColor: bgColorV, textColor: textColorV, linkColor: linkColorV } = values;

  const styleNav = {
    type: typeV,
    bgColor: bgColorV,
    textColor: textColorV,
    linkColor: linkColorV,
  };

  return (
    <MockupPhone>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="40vh">
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <MDBox mt={1} pl={1} pt={1} pb={2}>
          <Grid container rowSpacing={1} columnSpacing={-1}>
            {data.map((element, index) => {
              const isLandscape = index % 3 === 2;
              const data = { title: element.title, image: element.mediaId?.url || defaultImage };
              return (
                <Grid item xs={isLandscape ? 12 : 6} key={index}>
                  <CardMockup
                    typeCard={styleNav.type}
                    linkColor={styleNav.linkColor}
                    backgroundColor={styleNav.bgColor}
                    textColor={styleNav.textColor}
                    isLandscape={isLandscape}
                    data={data}
                  />
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
      )}
    </MockupPhone>
  );
});

export default MockupPhoneCard;
