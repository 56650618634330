import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import { Form, Formik } from "formik";
import form from "pages/element/schemas/subCategory/form";
import validations from "pages/element/schemas/subCategory/validations";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useCreateSubCategory, useUpdateSubCategory } from "../element.hook";
import { ISubCategory } from "../element.type";

interface Props {
  onFormSubmit: () => void;
  onError: (error: any) => void;
  currentSubCategory?: ISubCategory;
}

const ModalCreateOrUpdateCat = forwardRef(
  ({ onFormSubmit, onError, currentSubCategory }: Props, ref) => {
    const { t } = useTranslation();
    const formikRef = useRef(null);
    const { formId, formField } = form;
    const { name } = formField;
    const createSubCategory = useCreateSubCategory();
    const updateSubCategory = useUpdateSubCategory();

    const initialValues = {
      [name.name]: currentSubCategory?.name || "",
    };

    const handleSubmit = async (values: any) => {
      values.name = values.name.trim().replace(/\s+/g, " ");
      try {
        if (currentSubCategory) {
          await updateSubCategory.mutateAsync({
            subCategoryId: currentSubCategory._id,
            name: values.name,
          });
        } else await createSubCategory.mutateAsync(values.name);
        if (onFormSubmit) onFormSubmit();
      } catch (error) {
        if (onError) onError(error);
      }
    };

    useImperativeHandle(ref, () => ({
      async submitForm() {
        if (formikRef.current) await formikRef.current.submitForm();
      },
    }));

    return (
      <>
        <MDBox mt={3} mb={1} ml={0.5}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ values, errors, touched }) => {
              const { name } = formField;
              const { name: nameV } = values;

              return (
                <Form id={formId} autoComplete="off">
                  <MDBox my={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <FormField
                          type={name.type}
                          label={t("form.name")}
                          name={name.name}
                          value={nameV}
                          error={errors.name && touched.name}
                          success={nameV?.length > 0 && !errors.name}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </>
    );
  }
);

export default ModalCreateOrUpdateCat;
