import * as Yup from "yup";
import checkout from "pages/element/schemas/product/form";
import i18next from "i18n";

const {
  formField: { name, title, category, price, description, image, file },
} = checkout;

export const validationsUpdate = Yup.object().shape({
  [name.name]: Yup.string().required(i18next.t(name.errorMsgKey)),
  [title.name]: Yup.string().required(i18next.t(title.errorMsgKey)),
  [category.name]: Yup.string().required(i18next.t(category.errorMsgKey)),
  [description.name]: Yup.string().required(i18next.t(description.errorMsgKey)),
  [price.name]: Yup.string().required(i18next.t(price.errorMsgKey)),
  [image.name]: Yup.string().required(i18next.t(image.errorMsgKey)),
});

export const validationsCreate = [
  Yup.object().shape({
    [name.name]: Yup.string().required(i18next.t(name.errorMsgKey)),
    [title.name]: Yup.string().required(i18next.t(title.errorMsgKey)),
    [category.name]: Yup.string().required(i18next.t(category.errorMsgKey)),
    [price.name]: Yup.string().required(i18next.t(price.errorMsgKey)),
  }),
  Yup.object().shape({
    [description.name]: Yup.string().required(i18next.t(description.errorMsgKey)),
  }),
  Yup.object().shape({
    [file.name]: Yup.mixed().test("is-file", i18next.t(file.errorMsgKey), (value) => {
      return value instanceof File;
    }),
  }),
];
