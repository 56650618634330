import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MockupPhone from "components/MockupPhone/MockPhone";
import { useGetClientById } from "pages/client/client.hook";
// import { useTranslation } from "react-i18next";
import React from "react";
import { ECurrency, SELECT_INFORMATION } from "types/client.type";
import { IAttribute, IAttributeOrder } from "../element.type";

interface Props {
  image: string | Blob;
  formData: any;
  allAttributes: IAttribute[];
}

const MockupProduct = ({ image, formData, allAttributes }: Props): JSX.Element => {
  // const { t } = useTranslation();
  const { values } = formData;
  const isBlob = image instanceof Blob;
  const { data } = useGetClientById(SELECT_INFORMATION);

  const {
    title: titleV,
    price: priceV,
    description: descriptionV,
    attributes: attributesV,
  } = values;

  const productAttributesId = attributesV?.map(
    (attribute: IAttributeOrder) => attribute.productAttributesId
  );

  const currentAttributes = allAttributes
    .filter((row) => productAttributesId?.includes(row._id))
    .sort((a, b) => {
      const orderA = attributesV.find(
        (item: IAttributeOrder) => item.productAttributesId === a._id
      );
      const orderB = attributesV.find(
        (item: IAttributeOrder) => item.productAttributesId === b._id
      );
      return orderA.order - orderB.order;
    });

  const styledDescriptionV = `
    <style>
      ul {
        margin-top: 1em;
      }
    </style>
    ${descriptionV}
  `;

  const renderAttribute = ({ _id, mediaId, name, value }: IAttribute) => (
    <MDBox key={_id} component="li" display="flex" alignItems="center" py={1}>
      {mediaId?.url && (
        <MDBox mr={2}>
          <MDBox
            component="img"
            src={mediaId.url}
            alt="image product"
            borderRadius="10px"
            width="45px"
            height="45px"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </MDBox>
      )}
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium" color="#000">
          {name}
        </MDTypography>
        <MDTypography variant="caption" color="#737">
          {value}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  return (
    <MockupPhone dataFooterProduct={formData?.values}>
      <MDBox>
        <MDBox
          position="relative"
          borderRadius="lg"
          // mt={-2}
          mx={1.5}
          className="card-header"
          sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
        >
          <MDBox
            component="img"
            src={isBlob ? URL.createObjectURL(image) : image}
            alt="Element Image"
            borderRadius="lg"
            shadow="sm"
            width="100%"
            height={190}
            position="relative"
            zIndex={10}
            mb={2}
            mt={2}
          />
        </MDBox>
        <MDBox pb={12} px={2}>
          <MDBox>
            <Grid container>
              <Grid item xs={6} sm={8}>
                <MDTypography variant="h6" fontWeight="bold" color="#000">
                  {titleV}
                </MDTypography>
              </Grid>
              <Grid item xs={6} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDTypography variant="h6" fontWeight="regular" sx={{ mb: 2 }}>
                  {priceV ? priceV.toFixed(2) : 0}{" "}
                  {data?.settings?.currency === ECurrency.EUR ? "€" : "$"}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox>
            <MDBox
              dangerouslySetInnerHTML={{ __html: styledDescriptionV.replace(/<p>/g, "<p><br>") }} // Affichage du HTML dans le Box
              sx={{ fontSize: "0.7rem", lineHeight: 1 }}
            />
          </MDBox>

          <MDBox sx={{ mt: 4, fontSize: 13 }}>
            {currentAttributes.map((attribute: IAttribute) => renderAttribute(attribute))}
          </MDBox>
        </MDBox>
      </MDBox>
    </MockupPhone>
  );
};

export default React.memo(MockupProduct);
