import { CircularProgress, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useGetClientById } from "pages/client/client.hook";
import { ETypeElement } from "pages/element/element.type";
import { useTranslation } from "react-i18next";
import { SELECT_DESIGN } from "types/client.type";

interface Props {
  data: { nameRedirection: string; link: string; typeElement: string };
}
const MockupFooterProduct = ({ data }: Props) => {
  const { t } = useTranslation();
  const redirectionNameTrad =
    data.typeElement === ETypeElement.APPOINTMENT ? t("element.rdv") : t("element.moreDetail");
  const { data: currentDesign, isLoading: isLoadingCurrentDesign } =
    useGetClientById(SELECT_DESIGN);
  const redirectionValid = data.typeElement !== ETypeElement.PRODUCT && data.typeElement !== "None";
  return (
    <>
      <MDBox style={{ display: "flex", width: 150 }}>
        {isLoadingCurrentDesign ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <MDBox>
            <MDBox
              display="flex"
              color="white"
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: redirectionValid ? "space-between" : "center",
                height: 70,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                backgroundColor: "rgba(255,255,255,0.5)", // changer la couleur de fond selon vos besoins
                zIndex: 1000, // assure que le footer est toujours au-dessus du contenu
              }}
            >
              {redirectionValid && (
                <MDButton
                  href={data.link}
                  target="_blank"
                  style={{
                    padding: 6,
                    borderRadius: 20,
                    backgroundColor: currentDesign?.design?.navigation?.bgColor,
                    height: 20,
                    margin: 15,
                  }}
                  // onPress={() => handleLink(element.redirection?.link as string)}
                >
                  <MDTypography
                    variant="button"
                    // fontWeight={font.weight ? font.weight : "regular"}
                    sx={{ color: currentDesign?.design?.navigation?.textColor, padding: 1 }}
                  >
                    {redirectionNameTrad}
                  </MDTypography>
                </MDButton>
              )}
              <MDButton
                variant="contained"
                color="success"
                style={{ borderRadius: 50, margin: 15 }}
                iconOnly
              >
                <Icon>add</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default MockupFooterProduct;
