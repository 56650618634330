import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import { deleteOrder, getAllOrders, updateOrder, getAllEvents } from "./order.service";
import { IOrder, IStatusOrder, IEvent } from "./order.type";

enum KeyQueryOrder {
  getAllOrder = "getAllOrder",
  getAllEvents = "getAllEvents",
}

export const useGetAllOrder = (): UseQueryResult<IOrder[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryOrder.getAllOrder, clientId], () => {
    if (!clientId) return null;

    return getAllOrders(clientId);
  });
};

export const useDeleteOrder = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (orderId: string) => deleteOrder({ clientId, orderId }),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryOrder.getAllOrder, clientId],
        (prevOrders: IOrder[] | undefined) => {
          return prevOrders?.filter((order) => order._id !== result._id) || [];
        }
      );
    },
  });
};

export const useUpdateOrder = () => {
  const queryClient = useQueryClient();
  const { clientId } = useContext(AuthContext);

  return useMutation<IOrder, Error, { orderId: string; data: { status: IStatusOrder } }>({
    mutationFn: async ({ orderId, data }) => {
      return updateOrder({ clientId, orderId, data });
    },
    onSuccess: (result, variables) => {
      queryClient.setQueryData([KeyQueryOrder.getAllOrder, clientId], (prevOrders: IOrder[]) => {
        if (!prevOrders) return prevOrders;

        const updatedOrders = prevOrders.map((order) => {
          if (order._id === variables.orderId) return { ...order, status: variables.data.status };
          return order;
        });

        return updatedOrders;
      });
    },
  });
};

export const useGetAllEvents = (): UseQueryResult<IEvent[]> => {
  const { clientId } = useContext(AuthContext);

  return useQuery([KeyQueryOrder.getAllEvents, clientId], () => {
    if (!clientId) return null;

    return getAllEvents(clientId);
  });
};
