import { Button, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ISection } from "pages/design/design.type";
import { useGetAllSubCategory } from "pages/element/element.hook";
import { memo, useState } from "react";
import { BLACKCOLOR, WHITECOLOR } from "types/app.type";

interface Props {
  section?: ISection;
}

const MockupSectionCategory = memo(({ section }: Props): JSX.Element => {
  const { data, isLoading } = useGetAllSubCategory();
  const [selectedButton, setSelectedButton] = useState(0);
  const handleButtonClick = (index: number) => setSelectedButton(index);

  const custome = {
    bgColor: section?.bgColor || WHITECOLOR,
    buttonBgColor: section?.buttonBgColor || WHITECOLOR,
    textColor: section?.textColor || BLACKCOLOR,
    borderColor: section?.borderColor || BLACKCOLOR,
    buttonActiveBgColor: section?.buttonActiveBgColor || BLACKCOLOR,
    textActiveColor: section?.textActiveColor || WHITECOLOR,
    borderActiveColor: section?.borderActiveColor || BLACKCOLOR,
  };

  return (
    <>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center" height="40vh">
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <MDBox
          sx={{
            display: "flex",
            overflowX: "auto",
            p: 1,
            bgcolor: custome.bgColor,
            scrollbarWidth: "none", // for Firefox
            "::-webkit-scrollbar": {
              display: "none", // for Webkit (Chrome, Safari, Edge)
            },
          }}
        >
          {data?.map((category, index) => (
            <Button
              key={index}
              variant="outlined"
              size="small"
              sx={{
                color: selectedButton === index ? custome.borderActiveColor : custome.borderColor,
                bgcolor:
                  selectedButton === index ? custome.buttonActiveBgColor : custome.buttonBgColor,
                border: 2,
                borderRadius: 6,
                m: 1,
                flexShrink: 0,
                "&:hover": {
                  backgroundColor:
                    selectedButton === index ? custome.buttonActiveBgColor : custome.buttonBgColor,
                },
              }}
              onClick={() => handleButtonClick(index)}
            >
              <MDTypography
                variant="body2"
                fontWeight="small"
                fontSize={14}
                sx={{
                  color: selectedButton === index ? custome.textActiveColor : custome.textColor,
                }}
              >
                {category.name}
              </MDTypography>
            </Button>
          ))}
        </MDBox>
      )}
    </>
  );
});

export default MockupSectionCategory;
