import api from "config/axios.config";
import { IEvent, IOrder, IStatusOrder } from "./order.type";

interface PropsOrder {
  clientId: string;
  orderId: string;
  data?: { status: IStatusOrder };
}

export const getAllOrders = (clientId: string): Promise<IOrder[]> => {
  return api.get(`/clients/${clientId}/order`);
};

export const deleteOrder = ({ clientId, orderId }: PropsOrder): Promise<IOrder> => {
  return api.delete(`/clients/${clientId}/order/${orderId}`);
};

export const updateOrder = ({ clientId, orderId, data }: PropsOrder): Promise<IOrder> => {
  return api.patch(`clients/${clientId}/order/${orderId}`, data);
};

export const getAllEvents = (clientId: string): Promise<IEvent[]> => {
  return api.get(`/clients/${clientId}/events`);
};
