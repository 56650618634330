import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDDropzone from "components/MDDropzone";
import MDTypography from "components/MDTypography";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  formData: any;
  defaultLogo: string;
}

const GeneralUpdate = ({ formData, defaultLogo }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { logo, name } = formField;
  const { name: nameV } = values;

  return (
    <>
      <Card style={{ minWidth: 400, maxHeight: "750px", overflowY: "auto" }}>
        <MDBox p={3}>
          {/* <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("design.tabs.navigation")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox> */}
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={name.type}
                  label={t("form.name")}
                  name={name.name}
                  value={nameV}
                  placeholder={name.placeholder}
                  error={errors.name && touched.name}
                  success={nameV?.length > 0 && !errors.name}
                />
              </Grid>
            </Grid>
            <MDBox>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                {t("form.logo")}
              </MDTypography>
              {useMemo(
                () => (
                  <MDDropzone
                    options={{
                      addRemoveLinks: false,
                      maxFiles: 1,
                      acceptedFiles: "image/png, image/jpg, image/jpeg",
                    }}
                    defaultImageUrl={defaultLogo}
                    onChange={(fileImage) => setFieldValue(logo.name, fileImage)}
                  />
                ),
                []
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default GeneralUpdate;
