import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";
import { Design, ETypeDesign } from "./design.type";
import { updateDesign } from "./design.service";
import { SELECT_DESIGN } from "types/client.type";

enum KeyQueryDesign {
  getById = "getClientByIds",
  getDesign = "getDesign",
}

interface IUseDesign {
  typeDesign: ETypeDesign;
  select: string;
  data: Design;
}

export const useUpdateDesign = () => {
  const { clientId } = useContext(AuthContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ typeDesign, select, data }: IUseDesign) =>
      updateDesign({ clientId, typeDesign, select, data }),
    onSuccess: (result) => {
      queryClient.setQueryData([KeyQueryDesign.getById, clientId, SELECT_DESIGN], result);
    },
  });
};
