import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  logo: string;
}

const Header = ({ name, logo }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(true);
  const handleSetVisible = () => setVisible(!visible);

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDBox component="img" src={logo} alt="logo" width={80} height="auto" />
          </Grid>
          <Grid item>
            <MDBox height="100%">
              <MDTypography variant="h5" fontWeight="medium">
                {name}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular">
                {visible ? t("myApp.settings.appMaintenance") : t("myApp.settings.appAvailable")}
              </MDTypography>
              <MDBox ml={1}>
                <Switch disabled checked={visible} onChange={handleSetVisible} />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default Header;
