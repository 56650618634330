import Navigation from "components/NavigationPage/Navigation";
import Settings from "pages/myApp/Pages/Settings";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Information from "pages/myApp/Pages/Information";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();

  const tabs = [
    { label: t("myApp.tabs.information"), content: <Information />, mode: "inside" },
    { label: t("myApp.tabs.settings"), content: <Settings />, mode: "inside" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Navigation stickyNavbar={true} tabs={tabs}>
        <Footer />
      </Navigation>
    </DashboardLayout>
  );
};

export default Index;
