import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import Modal from "components/Modal/modal";
import Calendar from "layouts/Calendar";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ETypeAction, IReservation, IStatusReservation } from "../../reservation/reservation.type";
import { useGetAllReservation, useUpdateReservation } from "../../reservation/reservations.hook";
// eslint-disable-next-line import/extensions
import ModalInfoAppointment from "../components/ModalInfoAppointment";
import { useGetClientById } from "pages/client/client.hook";
import { SELECT_INFORMATION } from "types/client.type";

const CalendarPage = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalCreateReservationRef = useRef(null);
  const { data, isLoading } = useGetAllReservation();
  const [fakeData, setFakeData] = useState([]);
  const { data: dataClient } = useGetClientById(SELECT_INFORMATION);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [typeAction, setTypeAction] = useState<ETypeAction>(ETypeAction.CREATE);
  const [openAlertCreateReservation, setOpenAlertCreateReservation] = useState(false);
  const [selectReservation, setSelectReservation] = useState<IReservation | null>(null);
  const [errorAlertTitle, setErrorAlertTitle] = useState<string | null>(null);
  const [errorAlertContent, setErrorAlertContent] = useState<string | null>(null);
  const [errorAlertTitleReservation, setErrorAlertTitleReservation] = useState<string | null>(null);
  const [errorAlertContentReservation, setErrorAlertContentReservation] = useState<string | null>(
    null
  );
  const updateReservation = useUpdateReservation();

  const handleActionUpdate = async (reservation: IReservation, status: IStatusReservation) => {
    try {
      await updateReservation.mutateAsync({
        reservationId: reservation.id,
        data: { status: status },
      });
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleOpenAppointment = (reservation: any) => {
    const resFound: any = data.find((x: any) => x._id == reservation.event._def.extendedProps._id);

    const res: IReservation = {
      ...reservation.event._def.extendedProps,
      start: resFound.start,
      end: resFound.end,
      id: resFound._id,
    };

    setSelectReservation(res);
    setTypeAction(ETypeAction.UPDATE);
    setOpenModalCreate(true);
  };

  const handleActionDelete = (reservation: IReservation) => {
    setSelectReservation(reservation);
    setOpenModal(true);
  };

  /*const handleDelete = async () => {
    try {
      await deleteReservation.mutateAsync(selectReservation?._id);
      setOpenModal(false);
      setOpenAlertDelete(true);
    } catch (error) {
      setErrorAlertTitle(t("alert.delete.titleError"));
      setErrorAlertContent(t("alert.delete.contentError", { item: selectReservation?.name }));
    }
  };*/

  const handleClose = () => setOpenModal(false);

  const renderAlertDelete = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("alert.delete.title")}
      content={t("alert.delete.content", { item: selectReservation?.name })}
      open={openAlertDelete}
      onClose={() => setOpenAlertDelete(false)}
      close={() => setOpenAlertDelete(false)}
      bgWhite
    />
  );

  const renderAlertError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const renderAlertConfirmation = (
    <MDSnackbar
      color="success"
      icon="success"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  const renderAlertCancelation = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={errorAlertTitle}
      content={errorAlertContent}
      open={!!errorAlertContent}
      onClose={() => setErrorAlertContent(null)}
      close={() => setErrorAlertContent(null)}
      bgWhite
    />
  );

  /*const renderModalDelete = (
    <Modal
      title={t("reservation.modalDelete.title")}
      openModal={openModal}
      body={<ModalDelete name={selectReservation?.name} />}
      handleValid={handleDelete}
      handleClose={handleClose}
      type={EType.delete}
    />
  );*/

  const handleShowError = () => {
    setTimeout(() => {
      // Display the error only if the data is still missing after 2 seconds.
      setShowError(!data);
    }, 2000);
  };

  const renderCreateOrUpdateReservation = (
    <Modal
      title={
        selectReservation?.status == IStatusReservation.CONFIRM
          ? t(`appointment.modal.${typeAction}.title`)
          : t(`appointment.modal.${typeAction}.request`)
      }
      openModal={openModalCreate}
      body={
        <ModalInfoAppointment
          currentAppointment={selectReservation}
          ref={modalCreateReservationRef}
        />
      }
      handleValid={() => {
        const newStatus = IStatusReservation.CONFIRM;
        handleActionUpdate(selectReservation, newStatus as IStatusReservation);
        setOpenModalCreate(false);
      }}
      handleAction={() => {
        const newStatus =
          selectReservation?.status == IStatusReservation.PENDING
            ? IStatusReservation.REFUSE
            : selectReservation?.status == IStatusReservation.CONFIRM
            ? IStatusReservation.CANCEL
            : null;
        handleActionUpdate(selectReservation, newStatus as IStatusReservation);
        setOpenModalCreate(false);
      }}
      handleClose={() => setOpenModalCreate(false)}
      valid={selectReservation?.status != IStatusReservation.CONFIRM}
      action={
        selectReservation?.status == IStatusReservation.PENDING ||
        selectReservation?.status == IStatusReservation.CONFIRM
      }
      actionLabel={
        selectReservation?.status == IStatusReservation.PENDING
          ? t(`appointment.modal.${typeAction}.refuse`)
          : selectReservation?.status == IStatusReservation.CONFIRM
          ? t(`appointment.modal.${typeAction}.cancel`)
          : ""
      }
      actionStyle="error"
    />
  );

  /*useEffect(() => {
    if (!data) {
      setFakeData(mockEvents);
    }
  }, []);*/
  return (
    <>
      <MDBox pt={1} pb={3}>
        {isLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <>
            {data ? (
              <Card>
                <Calendar
                  header={{}}
                  events={data}
                  eventClick={(info: any) => {
                    info.jsEvent.preventDefault(); // don't let the browser navigate
                    handleOpenAppointment(info);
                  }}
                />
              </Card>
            ) : (
              <>
                {handleShowError()}
                {showError ? (
                  <>{t("reservation.errorGetReservation")}</>
                ) : (
                  <MDBox display="flex" justifyContent="center" alignItems="center" height="60vh">
                    <CircularProgress color="inherit" />
                  </MDBox>
                )}
              </>
            )}
          </>
        )}
      </MDBox>

      {/*renderModalDelete*/}
      {renderAlertDelete}
      {renderAlertError}
      {renderAlertConfirmation}
      {renderAlertCancelation}
      {renderCreateOrUpdateReservation}
    </>
  );
};

export default React.memo(CalendarPage);
