// @mui material components
import Card from "@mui/material/Card";
import { useFormik } from "formik";
import * as Yup from "yup";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/Layout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { sendEmailResetPassword } from "services/firebase.service";
import { useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Cover(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [alertSendEmail, setAlertSendEmail] = useState<string | null>(null);
  const [isSend, setIsSend] = useState(true);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("validationSchema.invalid.email"))
      .required(t("validationSchema.required.email")),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { email } = values;
        await sendEmailResetPassword(email);
        setIsSend(true);
        setAlertSendEmail(t("auth.resetPassword.alertSendEmail"));

        setTimeout(() => {
          navigate("/authentication/sign-in");
        }, 5000);
      } catch (error: any) {
        setIsSend(false);
        setAlertSendEmail(error.message);
      }
    },
  });

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      {
        <MDSnackbar
          color={isSend ? "success" : "error"}
          icon={isSend ? "notifications" : "warning"}
          title={t("auth.resetPassword.title")}
          content={alertSendEmail}
          open={!!alertSendEmail}
          onClose={() => setAlertSendEmail(null)}
          close={() => setAlertSendEmail(null)}
          bgWhite
        />
      }
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" p={1} fontWeight="medium" color="white" mt={1}>
            {t("auth.resetPassword.title")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <MDBox mb={4}>
              <MDInput
                type="email"
                name="email"
                label={t("form.email")}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={
                  formik.touched.email && (
                    <MDTypography variant="inherit" fontWeight="regular" color="error" mt={1}>
                      {formik.errors.email}
                    </MDTypography>
                  )
                }
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                {t("auth.resetPassword.reset")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
