const form = {
  formId: "subCategory-form",
  formField: {
    name: {
      name: "name",
      type: "text",
      errorMsgKey: "validationSchema.required.name",
    },
  },
};

export default form;
