import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AddCategory from "pages/element/components/AddCategory";
import { ISubCategory } from "pages/element/element.type";
import { useTranslation } from "react-i18next";

interface props {
  formData: any;
  subCategoriesData: ISubCategory[];
}

const ProductCreateInfo = ({ formData, subCategoriesData }: props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, errors, touched } = formData;
  const { name, title, price } = formField;
  const { name: nameV, title: titleV, price: priceV } = values;

  return (
    <MDBox>
      <MDTypography variant="h5">Element Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              label={t("form.name")}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV?.length > 0 && !errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={title.type}
              label={t("form.title")}
              name={title.name}
              value={titleV}
              placeholder={title.placeholder}
              error={errors.title && touched.title}
              success={titleV?.length > 0 && !errors.title}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={price.type}
              label={t("form.price")}
              name={price.name}
              value={priceV}
              placeholder={price.placeholder}
              error={errors.price && touched.price}
              success={priceV?.length > 0 && !errors.price}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mb={3}>
              <AddCategory formData={formData} subCategories={subCategoriesData} />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default ProductCreateInfo;
