import { Box, Grid, Icon, Menu, MenuItem } from "@mui/material";
import mockupSearchBar from "assets/images/mockupSearchBar.png";
import mockupCarousel from "assets/images/mockupCarousel.png";
import mockupCategory from "assets/images/mockupCategory.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SectionCarousel from "components/Sections/SectionCarousel";
import SectionCategory from "components/Sections/SectionCategory";
import SectionProduct from "components/Sections/SectionProduct";
import SectionSearch from "components/Sections/SectionSearchBar";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { WHITECOLOR, BLACKCOLOR } from "types/app.type";
import { ETypeSections, ISection } from "../design.type";

interface Props {
  formData: any;
}

const HomePageUpdate = memo(({ formData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { sections } = formField;
  const { sections: sectionsV } = values;
  const [image, setImage] = useState<any>(mockupSearchBar);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleMenuItemClick = (type: ETypeSections) => {
    const sectionsCopy = [...sectionsV];
    const order = sectionsV.length > 1 ? sectionsV[sectionsV.length - 2].order + 1 : 0;

    let defaultStyle = null;
    switch (type) {
      case ETypeSections.CAROUSEL:
        defaultStyle = { type, order, bgColor: WHITECOLOR };
        break;
      case ETypeSections.CATEGORY:
        defaultStyle = {
          type,
          order,
          bgColor: WHITECOLOR,
          buttonBgColor: WHITECOLOR,
          textColor: BLACKCOLOR,
          borderColor: BLACKCOLOR,
          buttonActiveBgColor: BLACKCOLOR,
          textActiveColor: WHITECOLOR,
          borderActiveColor: BLACKCOLOR,
        };
        break;
      case ETypeSections.SEARCHBAR:
        defaultStyle = { type, order, bgColor: WHITECOLOR, textColor: BLACKCOLOR };
        break;
      default:
        defaultStyle = { type, order, bgColor: WHITECOLOR };
        break;
    }

    sectionsCopy.forEach((section) => {
      if (section.type === ETypeSections.PRODUCTS) section.order = order + 1;
    });

    setFieldValue(sections.name, [...sectionsCopy, defaultStyle]);
    setAnchorEl(null);
  };

  const handleDeleteSection = (index: number) => {
    const sectionsCopy = [...sectionsV];
    sectionsCopy.splice(index, 1);

    sectionsCopy.forEach((section, idx) => {
      section.order = idx;
    });

    setFieldValue(sections.name, sectionsCopy);
  };

  const handleSectionType = (type: ETypeSections, section: ISection, index: number) => {
    let componentToRender = null;
    switch (type) {
      case ETypeSections.CAROUSEL:
        componentToRender = (
          <SectionCarousel
            section={section}
            setFieldValue={setFieldValue}
            index={index}
            handleDeleteSection={handleDeleteSection}
          />
        );
        break;
      case ETypeSections.CATEGORY:
        componentToRender = (
          <SectionCategory
            section={section}
            setFieldValue={setFieldValue}
            index={index}
            handleDeleteSection={handleDeleteSection}
          />
        );
        break;
      case ETypeSections.SEARCHBAR:
        componentToRender = (
          <SectionSearch
            section={section}
            setFieldValue={setFieldValue}
            index={index}
            handleDeleteSection={handleDeleteSection}
          />
        );
        break;
      case ETypeSections.PRODUCTS:
        componentToRender = (
          <SectionProduct section={section} setFieldValue={setFieldValue} index={index} />
        );
        break;
      default:
        break;
    }

    return componentToRender;
  };

  const images = {
    [ETypeSections.SEARCHBAR]: mockupSearchBar,
    [ETypeSections.CAROUSEL]: mockupCarousel,
    [ETypeSections.CATEGORY]: mockupCategory,
  } as Record<ETypeSections, string>;

  const handleMenuItemHover = (itemName: ETypeSections) => {
    setImage(images[itemName]);
  };

  return (
    <>
      {sectionsV
        ?.sort((a: ISection, b: ISection) => a.order - b.order)
        .map((section: ISection, index: number) => {
          return <div key={index}> {handleSectionType(section.type, section, index)} </div>;
        })}
      <MDBox sx={{ textAlign: "center", mt: 2 }}>
        <MDButton
          variant="gradient"
          color="info"
          sx={{ borderRadius: 50 }}
          onClick={handleClick}
          iconOnly
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>

      <Menu id="demo-positioned-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MenuItem
              disabled={sectionsV.some(
                (section: ISection) => section.type === ETypeSections.SEARCHBAR
              )}
              onMouseEnter={() => handleMenuItemHover(ETypeSections.SEARCHBAR)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.SEARCHBAR)}
              onClick={() => handleMenuItemClick(ETypeSections.SEARCHBAR)}
            >
              {t("design.section.search.title")}
            </MenuItem>
            <MenuItem
              disabled={sectionsV.some(
                (section: ISection) => section.type === ETypeSections.CAROUSEL
              )}
              onMouseEnter={() => handleMenuItemHover(ETypeSections.CAROUSEL)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.CAROUSEL)}
              onClick={() => handleMenuItemClick(ETypeSections.CAROUSEL)}
            >
              {t("design.section.carousel.title")}
            </MenuItem>
            <MenuItem
              disabled={sectionsV.some(
                (section: ISection) => section.type === ETypeSections.CATEGORY
              )}
              onMouseEnter={() => handleMenuItemHover(ETypeSections.CATEGORY)}
              onTouchStart={() => handleMenuItemHover(ETypeSections.CATEGORY)}
              onClick={() => handleMenuItemClick(ETypeSections.CATEGORY)}
            >
              {t("design.section.category.title")}
            </MenuItem>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Box display="flex" pl={2} justifyContent="center" maxWidth="80vw" maxHeight="80vh">
              <MDBox component="img" src={image} alt="Brand" maxWidth={400} maxHeight={400} />
            </Box>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
});

export default HomePageUpdate;
