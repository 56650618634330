import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import SelectColor from "components/Select/SelectColor";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { EDesignTypeCard } from "../design.type";

interface Props {
  formData: any;
}

const CardUpdate = memo(({ formData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { formField, values, setFieldValue } = formData;
  const { type, bgColor, textColor, linkColor } = formField;
  const { type: typeV, bgColor: bgColorV, textColor: textColorV, linkColor: linkColorV } = values;

  const selectTypeBorder = [
    { id: EDesignTypeCard.BORDER, label: t("design.typeBorderCard.border") },
    { id: EDesignTypeCard.NOBORDER, label: t("design.typeBorderCard.noBorder") },
  ];

  return (
    <>
      <Card style={{ maxHeight: "750px", overflowY: "auto" }}>
        <MDBox p={3}>
          {/* <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("design.tabs.navigation")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox> */}
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  defaultValue={
                    selectTypeBorder.find((item) => item.id === typeV) || selectTypeBorder[0]
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => {
                    setFieldValue(type.name, value !== null ? value.id : "");
                  }}
                  options={selectTypeBorder}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label={t("form.typeBorder")} />}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={bgColor.name}
                  label={t("form.bgColor")}
                  value={bgColorV}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={textColor.name}
                  label={t("form.textColor")}
                  value={textColorV}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectColor
                  fieldName={linkColor.name}
                  label={t("form.linkColor")}
                  value={linkColorV}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
});

export default CardUpdate;
