import Navigation from "components/NavigationPage/Navigation";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomeGeneral from "pages/technicalService/pages/CustomeGeneral";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();

  const tabs = [{ label: t("design.tabs.general"), content: <CustomeGeneral />, mode: "inside" }];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Navigation stickyNavbar={true} tabs={tabs}>
        <Footer />
      </Navigation>
    </DashboardLayout>
  );
};

export default Index;
