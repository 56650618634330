import * as Yup from "yup";
import checkout from "pages/design/schemas/homePage/form";
import i18next from "i18n";
import { ETypeSections } from "pages/design/design.type";

const {
  formField: { type, sections },
} = checkout;

const sectionSchema = Yup.object().shape({
  type: Yup.string()
    .required(i18next.t("validationSchema.required.type"))
    .oneOf(Object.values(ETypeSections), i18next.t("validationSchema.invalid.type")),
  // order: Yup.number().required(i18next.t("validationSchema.required.order")),
  bgColor: Yup.string().required(i18next.t("validationSchema.required.bgColor")),
  buttonActiveBgColor: Yup.string(),
  buttonBgColor: Yup.string(),
  textActiveColor: Yup.string(),
  textColor: Yup.string(),
  borderActiveColor: Yup.string(),
  borderColor: Yup.string(),
});

const validations = Yup.object().shape({
  [type.name]: Yup.string().required(i18next.t(type.errorMsgKey)),
  [sections.name]: Yup.array().of(sectionSchema).required(i18next.t(sections.errorMsgKey)),
});

export default validations;
