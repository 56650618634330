import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import SelectColor from "components/Select/SelectColor";
import { useTranslation } from "react-i18next";

interface Props {
  formData: any;
}

const InformationUpdate = ({ formData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const defaultSocial = "https://...";
  const { formField, values, errors, touched, setFieldValue, initialValues } = formData;
  const { description: descriptionI } = initialValues;
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const {
    webSite,
    twitter,
    facebook,
    instagram,
    linkedin,
    description,
    bgColorCard,
    address,
    linkAddress,
    openingHours,
  } = formField;
  const {
    webSite: webSiteV,
    twitter: twitterV,
    instagram: instagramV,
    linkedin: linkedinV,
    facebook: facebookV,
    bgColorCard: bgColorCardV,
    address: addressV,
    linkAddress: linkAddressV,
    openingHours: openingHoursV,
  } = values;

  return (
    <>
      <Card style={{ maxHeight: "750px", overflowY: "auto" }}>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("myApp.titleSocials")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={webSite.type}
                  label={t("myApp.socials.webSite")}
                  name={webSite.name}
                  value={webSiteV || defaultSocial}
                  placeholder={webSite.placeholder}
                  success={webSiteV?.length > 0 && !errors.webSite}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={instagram.type}
                  label={t("myApp.socials.instagram")}
                  name={instagram.name}
                  value={instagramV || defaultSocial}
                  placeholder={instagram.placeholder}
                  success={instagramV?.length > 0 && !errors.instagram}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={facebook.type}
                  label={t("myApp.socials.facebook")}
                  name={facebook.name}
                  value={facebookV || defaultSocial}
                  placeholder={facebook.placeholder}
                  success={facebookV?.length > 0 && !errors.facebook}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={twitter.type}
                  label={t("myApp.socials.twitter")}
                  name={twitter.name}
                  value={twitterV || defaultSocial}
                  placeholder={twitter.placeholder}
                  success={twitterV?.length > 0 && !errors.twitter}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={linkedin.type}
                  label={t("myApp.socials.linkedin")}
                  name={linkedin.name}
                  value={linkedinV || defaultSocial}
                  placeholder={linkedin.placeholder}
                  success={linkedinV?.length > 0 && !errors.linkedin}
                />
              </Grid>
            </Grid>
            <MDBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SelectColor
                    fieldName={bgColorCard.name}
                    label={t("form.bgColor")}
                    value={bgColorCardV}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("myApp.aboutUs")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={address.type}
                  label={t("myApp.addressOptional")}
                  name={address.name}
                  value={addressV}
                  placeholder={address.placeholder}
                  success={addressV?.length > 0 && !errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={linkAddress.type}
                  label={t("myApp.linkMapAddress")}
                  name={linkAddress.name}
                  value={linkAddressV}
                  placeholder={linkAddress.placeholder}
                  success={linkAddressV?.length > 0 && !errors.linkAddress}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Description&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDEditor
                  value={(content) => setFieldValue(description.name, content)}
                  defaultValue={descriptionI}
                  maxLength={1000}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <MDBox p={3}>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <MDTypography variant="h5">{t("myApp.openingHours.title")}</MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={3}>
            {days.map((day) => {
              return (
                <Grid key={day} container spacing={3}>
                  <Grid item xs={12} sm={2}>
                    <MDTypography variant="subtitle2">
                      {t(`myApp.openingHours.${day.toLowerCase()}`)}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormField
                      type="time"
                      label={t("myApp.openingHours.open")}
                      name={linkAddress.name}
                      value={
                        openingHoursV?.find((x: { day: string }) => x.day == day)?.open || "09:00"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormField
                      type="time"
                      label={t("myApp.openingHours.close")}
                      name={linkAddress.name}
                      value={
                        openingHoursV?.find((x: { day: string }) => x.day == day)?.close || "17:00"
                      }
                    />
                  </Grid>
                </Grid>
              );
            })}
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
};

export default InformationUpdate;
