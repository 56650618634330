const form = {
  formId: "attribute-form",
  formField: {
    title: {
      name: "title",
      type: "text",
      errorMsgKey: "validationSchema.required.title",
    },
    subTitle: {
      name: "subTitle",
      type: "text",
      errorMsgKey: "validationSchema.required.subTitle",
    },
    file: {
      name: "file",
      type: "file",
      errorMsgKey: "validationSchema.required.file",
    },
  },
};

export default form;
