import * as Yup from "yup";
import checkout from "pages/design/schemas/navigation/form";
import i18next from "i18n";

const {
  formField: { bgColor, textColor, activeBgColor },
} = checkout;

const validations = Yup.object().shape({
  [bgColor.name]: Yup.string().required(i18next.t(bgColor.errorMsgKey)),
  [textColor.name]: Yup.string().required(i18next.t(textColor.errorMsgKey)),
  [activeBgColor.name]: Yup.string().required(i18next.t(activeBgColor.errorMsgKey)),
});

export default validations;
