import * as Yup from "yup";
import checkout from "pages/element/schemas/attribute/form";
import i18next from "i18n";

const {
  formField: { title, subTitle, file },
} = checkout;

const validations = Yup.object().shape({
  [title.name]: Yup.string().required(i18next.t(title.errorMsgKey)),
  [subTitle.name]: Yup.string().required(i18next.t(subTitle.errorMsgKey)),
  [file.name]: Yup.mixed().test("is-file", i18next.t(file.errorMsgKey), (value) => {
    return value instanceof File;
  }),
});

export default validations;
