const form = {
  formId: "design-general-form",
  formField: {
    name: {
      name: "name",
      type: "text",
      errorMsgKey: "validationSchema.required.name",
    },
    logo: {
      name: "logo",
      type: "file",
      errorMsgKey: "validationSchema.required.file",
    },
  },
};

export default form;
