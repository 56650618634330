export interface IProduct {
  _id: string;
  name: string;
  typeElement?: ETypeElement;
  title: string;
  price: number;
  subCategoryId: string | ISubCategory;
  sub_categories_doc: {
    _id: string;
    categoryId: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  mediaId?: {
    url: string;
    _id: string;
  };
  attributes: IAttributeOrder[];
  description: string;
  inCarousel: boolean;
  redirection?: IRedirection;
  subscriptionIds?: string[];
  documents?: IDocuments;
  reservation?: IReservationElement;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export enum ETypeElement {
  PRODUCT = "Product",
  // None = "None",
  DETAIL = "More details",
  INFORMATION = "Information",
  RENT = "Rent",
  APPOINTMENT = "Appointment",
}

export interface IProductUpdate {
  id: string;
  name?: string;
  title?: string;
  price?: number;
  image?: string;
  subCategoryId?: string;
  description?: string;
  file?: Dropzone.DropzoneFile;
  attributes?: IAttributeOrder[];
  inCarousel?: boolean;
  productActionType?: string;
  link?: string;
  isMultipleReservation?: string;
  isAutomaticConfirmation?: string;
  durationDays?: number;
  durationHours?: number;
  durationMinutes?: number;
}

export interface IRedirection {
  readonly name: string;
  readonly link?: string;
}

export interface IProductCreate {
  name: string;
  title: string;
  price: number;
  image: string;
  subCategoryId: string;
  description: string;
  file: Dropzone.DropzoneFile;
  attributes?: IAttributeOrder[];
}

export interface ISubCategory {
  readonly _id?: string;
  categoryId: string;
  name: string;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export interface IAttribute {
  readonly _id?: string;
  name: string;
  value: string;
  type?: string;
  mediaId?: {
    url: string;
    _id: string;
  };
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export interface IAttributeOrder {
  productAttributesId: string;
  order: number;
  details?: IAttribute;
}
export interface IAttributeCreate {
  title: string;
  subTitle: string;
  file?: Dropzone.DropzoneFile;
}

export enum ETypeAction {
  CREATE = "create",
  UPDATE = "update",
}

export enum ETypeActionButtonProduct {
  DETAIL = "More details",
  APPOINTMENT = "Appointment",
  RENT = "Rent",
  NONE = "None",
}

export enum EMenuElement {
  INFORMATION = "Information",
  ACTIONS = "Action button",
  NONE = "None",
}

export interface IDocuments {
  isConfidential: boolean;
  media: any[];
  privateMedia?: any[];
}

export interface IReservationElement {
  duration: IDuration;
  isMultipleReservation: boolean;
  isAutomaticConfirmation: boolean;
}

export interface IDuration {
  days: number;
  hours: number;
  minutes: number;
}
