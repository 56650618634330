import axios from "axios";

import { EFeatures } from "types/features.type";

import {
  DataFeatures,
  ECurrency,
  IClient,
  ICreateClient,
  SELECT_CUSTOMERS,
} from "types/client.type";
import { getAuthToken } from "./firebase.service";
import { Design, ETypeDesign } from "pages/design/design.type";

const api = axios.create({ baseURL: process.env.REACT_APP_HOPELAPP_API });

api.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();

    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) throw error.response.data;
    else if (error.request) throw new Error("Unable to make request");
    else throw error;
  }
);

export async function getClients(): Promise<IClient[]> {
  return api.get(`/clients?select=${SELECT_CUSTOMERS}`);
}

export async function getTemplates(): Promise<IClient[]> {
  return api.get(`/clients/templates`);
}

export async function getClientsById(id: string, select?: string): Promise<IClient> {
  return api.get(`clients/${select ? `${id}?select=${select}` : id}`);
}

export async function createClient(data: ICreateClient): Promise<IClient> {
  return api.post("clients", data);
}

export async function updateClient(
  id: string,
  data: FormData | object,
  select?: string
): Promise<IClient> {
  return api.patch(`clients/${select ? `${id}?select=${select}` : id}`, data);
}

export async function updateFeature(
  id: string,
  feature: EFeatures,
  dataFeature: DataFeatures,
  select?: string
): Promise<IClient> {
  return api.patch(`clients/${id}/feature/${feature}${select ? `?select=${select}` : ""}`, {
    dataFeature,
  });
}

export async function updateDesign(
  id: string,
  design: ETypeDesign,
  dataDesign: Design,
  select?: string
): Promise<IClient> {
  return api.patch(
    `clients/${id}/design/${design}${select ? `?select=${select}` : ""}`,
    dataDesign
  );
}

export async function deleteClient(id: string) {
  return api.delete(`clients/${id}`);
}

export async function updateClientCurrency(
  clientId: string,
  data: { currency: ECurrency },
  select: string
): Promise<IClient> {
  return api.patch(`clients/${clientId}/currency${select ? `?select=${select}` : ""}`, data);
}
