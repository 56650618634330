import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

interface Props {
  feature: any;
}

const ModalDelete = ({ feature }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MDBox mt={3} mb={1} ml={0.5}>
      <MDTypography variant="body2" fontWeight="regular" color="text">
        {feature.enabled
          ? t("element.modalConfirm.bodyDeactivate")
          : t("element.modalConfirm.bodyActivate")}
      </MDTypography>
      <MDTypography variant="h6" color="text" textAlign="center">
        {t(`hopelappStore.${feature.key}.label`)}
      </MDTypography>
    </MDBox>
  );
};

export default ModalDelete;
