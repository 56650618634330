import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
}

const ModalDelete = ({ name }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MDBox mt={3} mb={1} ml={0.5}>
      <MDTypography variant="body2" fontWeight="regular" color="text">
        {t("element.modalDelete.body")}
      </MDTypography>
      <MDTypography variant="h6" color="text" textAlign="center">
        {name}
      </MDTypography>
    </MDBox>
  );
};

export default ModalDelete;
